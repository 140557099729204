import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import LoginContainer from './LoginContainer';
import LoginForm from './LoginForm';
import Locale from '../Translate/Locale';
import login from '../../actions/login';
import Heading1 from '../common/Heading1';
import ErrorIcon from '../../icons/error2.svg';

import './LoginPage.scss';

export class LoginPage extends React.Component {
  onSubmit = ({ email, password }) => {
    const { login } = this.props;
    login(email, password);
  };

  render() {
    const { auth, resetPassword } = this.props;

    return (
      <LoginContainer>
        <div styleName="login-box" >
          <Heading1 styleName="title">
          Sign in to Sendwise
          </Heading1>
          <div styleName={classnames('sub-title',{error: !!auth.error})}>
            <Locale>Enter your details below</Locale>
          </div>
          { auth.error && (
            <div styleName="error-container" data-test-id="login-error">
              <ErrorIcon styleName="icon" />
              <Locale>Sorry, the credentials used are wrong. Please try again or create a new account</Locale>
            </div>
          ) }
          { resetPassword.status && (
            <div styleName="reset-container">
              <Locale>Your password has been reset. Please sign in again</Locale>
            </div>
          ) }
          <LoginForm
            onSubmit={this.onSubmit}
            isLoading={auth.isLoading}
          />
        </div>
      </LoginContainer>
    );
  }
}

LoginPage.defaultProps = {
  login: () => {},
};

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    auth,
    resetPassword,
  } = state;

  return {
    auth,
    resetPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(login(email, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);