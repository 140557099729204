import Bottle from 'bottlejs';
import { createBrowserHistory } from 'history';
import { configureAppStore } from './store';
import { createFirewall } from './firewall';
import { createNotificationsApi, createSendwiseApi, createTouchpointsApi } from './api';
import { initI18n } from './i18n';

import getPermissions from './actions/permissions';
const bottle = new Bottle();

bottle.factory('store', () => configureAppStore());
bottle.factory('firewall', (container) => createFirewall(container.store));
bottle.factory('api', (container) => createSendwiseApi(container.store));
bottle.factory('touchpointsApi', (container) => createTouchpointsApi(container.store));
bottle.factory('notificationsApi', (container) => createNotificationsApi(container.store));
bottle.factory('history', () => createBrowserHistory());
bottle.factory('i18n', (container) => initI18n(container.store));

bottle.value('actions.getPermissions', getPermissions);
bottle.value('env', {
  refreshTokenInterval: process.env.SENDWISE_REFRESH_TOKEN_INTERVAL || 30,
});

export default bottle.container;
