import React from 'react';
import PropTypes from 'prop-types';

import InvoiceInfoStep from '../common/InvoiceInfoStep';
import InvestigationStepContainer from './InvestigationStepContainer';

function InvestigationInvoiceInfoStep(props) {
  return <InvoiceInfoStep {...props} as={InvestigationStepContainer} isSubmitButton={true} />;
}

InvestigationInvoiceInfoStep.propTypes = {
  claim: PropTypes.object,
  onChange: PropTypes.func,
};

export default React.memo(InvestigationInvoiceInfoStep);
