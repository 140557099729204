import { useState, useCallback } from 'react';
import container from '../../container';
import { useSelectedShopId } from '../../hooks/useSelectedShop';

function useSearchClaims(history) {
  const shopId = useSelectedShopId();
  const [isSearchPending, setIsSearchPending] = useState(false);
  const [error, setError] = useState(null);
  const [searchedClaims, setSearchedClaims] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const setTrackingCode = (trackingCode) => {
    history.replace('/parcel-finder/claims-center' + (trackingCode === null ? '' : `?trackingCode=${trackingCode}`));
  };

  const trackingCode = new URLSearchParams(history.location.search).get('trackingCode');

  const searchClaim = useCallback(
    async (trackingCode) => {
      setIsSearchPending(true);
      setError(null);
      if (trackingCode) {
        try {
          const { claims } = await container.touchpointsApi.getClaimsData({
            shopId,
            trackingCode,
          });
          setSearchedClaims(claims);
          setIsLoaded(true);
        } catch (err) {
          setError(err);
        }
      }
      setIsSearchPending(false);
    },
    [shopId]
  );

  const resetSearch = () => {
    setSearchedClaims([]);
    setIsLoaded(false);
    setTrackingCode('');
  };

  return {
    isSearchPending,
    error,
    trackingCode,
    searchedClaims,
    isLoaded,
    searchClaim,
    setTrackingCode,
    resetSearch,
  };
}

export default useSearchClaims;
