import React from 'react';

import SearchClaimInfoStep from '../common/SearchClaimInfoStep';
import MissingItemStepContainer from './MissingItemStepContainer';

function MissingItemSearchClaimInfoStep(props) {
  return <SearchClaimInfoStep {...props} as={MissingItemStepContainer} />;
}

export default React.memo(MissingItemSearchClaimInfoStep);
