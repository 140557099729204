import React from 'react';
import { trackEvent } from '../../utils/googleAnalytics';
import Button from '../common/Button';
import Link from '../common/Link';
import ErrorPage from './ErrorPage';

import './Error404.scss';

function Error404(props) {
  trackEvent({
    category: '404',
    action: props.location.pathname,
  });

  return (
    <ErrorPage
      upperHeader= "404 ERROR"
      header = "Lost Track"
      subHeader = "Sorry for that. I'm only human"
    >
      <div styleName="link-button">
        <Link to="/home" >
          <Button negativeOutline>
          Take me home
          </Button>
        </Link>
      </div>
    </ErrorPage>
  );
}

export default Error404;