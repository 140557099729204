import { matchPath } from 'react-router';

export function getPartFromPath(path, index) {
  const parts = path.split('/');

  if (parts.length >= index + 1) {
    return parts[index + 1];
  }

  return null;
}

/**
 * Matches (exact, but not strict) given pathname against object with urls and returns a key
 *
 * Example:
 * urls = { a: '/foo/a', b: '/foo/b/', c: '/foo/c/' }
 * pathname = '/foo/b'
 * result = 'b'
 *
 * @param pathname
 * @param urls (object: key/type -> pathname)
 * @returns {string} url object key if match is found or null otherwise
 */
export function getUrlType(pathname, urls) {
  return Object.keys(urls).find(type => matchPath(pathname, {
    path: urls[type],
    exact: true,
  }));
}