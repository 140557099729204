import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MissingItemStepContainer from './MissingItemStepContainer';
import ItemPicturesUploader from '../common/ItemPicturesUploader';
import ItemInfo from '../common/ItemInfo';
import CustomerCommentInput from '../common/CustomerCommentInput';
import useStepValidation from '../common/useStepValidation';
import { missingItemInfoValidator } from '../claimValidators';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import Column from '../../../common/layout/Column';

function MissingItemInfoStep(props) {
  const { onChange, claim, onNext } = props;
  const [errors, onClickNext] = useStepValidation(claim, onNext, missingItemInfoValidator);
  const [isDisabled, setIsDisabled] = useState(false);

  const onChangeDamageInfo = (missingItemInfo) => onChange({ missingItemInfo });
  const onInputChange = (value, name) => onChange({ [name]: value });

  return (
    <MissingItemStepContainer {...props} onNext={onClickNext} isDisabled={isDisabled}>
      <SidePanelContentHeader>Missing item information</SidePanelContentHeader>
      <Column spacing="small">
        <ItemInfo
          data={claim.missingItemInfo}
          emptyInfoErrorText="BAD_MISSING_ITEM_INFO_ITEMS"
          errorsWithTranslation={errors.missingItemInfo}
          onChange={onChangeDamageInfo}
        />
        <CustomerCommentInput claim={claim} onChange={onInputChange} />
        <ItemPicturesUploader
          name="picturesOfMissingItem"
          label="MISSING_ITEM_PICTURES_UPLOADER_LABEL"
          helpText="CLAIMS_DAMAGE_PICTURES_HELP_TEXT"
          claim={claim}
          errors={errors}
          onChange={onInputChange}
          onBusy={setIsDisabled}
        />
      </Column>
    </MissingItemStepContainer>
  );
}

MissingItemInfoStep.propTypes = {
  claim: PropTypes.shape({
    partialDamageInfo: PropTypes.arrayOf(PropTypes.object),
    picturesOfDamage: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onChange: PropTypes.func,
};

export default React.memo(MissingItemInfoStep);
