import React from 'react';
import PropTypes from 'prop-types';

import PartialDamageStepContainer from './PartialDamageStepContainer';
import InvoiceInfoStep from '../common/InvoiceInfoStep';

function PartialDamageInvoiceInfoStep(props) {
  return <InvoiceInfoStep {...props} as={PartialDamageStepContainer} isSubmitButton={true} />;
}

PartialDamageInvoiceInfoStep.propTypes = {
  claim: PropTypes.object,
  onChange: PropTypes.func,
};

export default React.memo(PartialDamageInvoiceInfoStep);
