import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import TrackingContainer from '../touchpoints/TrackingContainer';
import TrackingPagesOverview from './TrackingPagesOverview';
import {
  getAllLocalizationsByShopId,
  getLegacyTrackingPageByShopId,
  getTrackingPagesByShopId,
  selectedShopIdSelector,
  shopLocalizationsSelector,
  trackingPagesSelector,
} from '../../reducers/selectors';
import { getTrackingPages } from '../../actions/trackingPages';
import { getShopLocalizations } from '../../actions/shopLocalizations';
import {
  getLegacyTrackingPage,
  makeDefaultLegacyTrackingPage,
  removeLegacyTrackingPage,
} from '../../actions/legacyTrackingPage';
import { setDefaultTrackingPageConfirmed } from '../../actions/defaultTrackingPageActions';
import TouchpointsEmptyOverview from '../touchpoints/TouchpointsEmptyOverview/TouchpointsEmptyOverview';
import Loader from '../common/Loader/Loader';
import TouchpointsOverviewSwitch from '../touchpoints/TouchpointsOverviewSwitch';
import TouchpointsOverviewRedirect from '../touchpoints/TouchpointsOverviewRedirect';
import TrackingIcon from '../../icons/tracking.svg';

const trackingPageLink = 'https://support.portal.sevensenders.com/support/solutions/folders/15000003851';

const urls = {
  base: '/tracking/tracking-pages',
  start: '/tracking/tracking-pages/start',
  create: '/tracking/tracking-pages/create',
};

export function TrackingPagesPage(props) {
  const {
    shopId,
    isLoading,
    loaded,
    trackingPages = [],
    shopLocalizations,
    legacy,
    setDefaultTrackingPage,
    makeDefaultLegacyTrackingPage,
    getTrackingPages,
    getShopLocalizations,
    getLegacyTrackingPage,
    removeLegacyTrackingPage,
    location,
  } = props;

  useEffect(() => {
    getTrackingPages(shopId);
    getShopLocalizations(shopId);
    getLegacyTrackingPage(shopId);
  }, [shopId]);

  return (
    <TrackingContainer location={location}>
      {!isLoading && loaded && (
        <TouchpointsOverviewRedirect pathname={location.pathname} data={trackingPages} urls={urls} />
      )}
      <TouchpointsOverviewSwitch isLoading={isLoading} loaded={loaded} data={trackingPages}>
        <Loader key="loader" />
        <TouchpointsEmptyOverview
          key="empty"
          icon={TrackingIcon}
          pageTitle="No tracking pages yet"
          title="NO_TRACKING_PAGES"
          buttonText="CREATE_YOUR_FIRST_TRACKING_PAGE"
          buttonLink={urls.create}
          link={trackingPageLink}
          linkText="CREATE_TRACKING_PAGES"
        />
        <TrackingPagesOverview
          key="data"
          shopId={shopId}
          trackingPages={trackingPages}
          shopLocalizations={shopLocalizations}
          legacy={legacy}
          makeDefaultLegacyTrackingPage={makeDefaultLegacyTrackingPage}
          setDefaultTrackingPage={setDefaultTrackingPage}
          createUrl={urls.create}
          getTrackingPages={getTrackingPages}
          getLegacyTrackingPage={getLegacyTrackingPage}
          removeLegacyTrackingPage={removeLegacyTrackingPage}
        />
      </TouchpointsOverviewSwitch>
    </TrackingContainer>
  );
}

function mapStateToProps(state) {
  const shopId = selectedShopIdSelector(state);
  const dataSources = [trackingPagesSelector(state), shopLocalizationsSelector(state), state.legacyTrackingPage];

  return {
    shopId,
    isLoading: dataSources.some((x) => x.isLoading),
    loaded: trackingPagesSelector(state).loaded && shopLocalizationsSelector(state).loaded,
    trackingPages: getTrackingPagesByShopId(state, shopId),
    shopLocalizations: getAllLocalizationsByShopId(state, shopId),
    legacy: getLegacyTrackingPageByShopId(state, shopId),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTrackingPages: (shopId) => dispatch(getTrackingPages(shopId)),
    getShopLocalizations: (shopId) => dispatch(getShopLocalizations(shopId)),
    getLegacyTrackingPage: (shopId) => dispatch(getLegacyTrackingPage(shopId)),
    removeLegacyTrackingPage: (shopId) => dispatch(removeLegacyTrackingPage(shopId)),
    makeDefaultLegacyTrackingPage: (shopId) => dispatch(makeDefaultLegacyTrackingPage(shopId)),
    setDefaultTrackingPage: (...args) => dispatch(setDefaultTrackingPageConfirmed(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingPagesPage);
