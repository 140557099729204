import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../error-pages/Error404';
import ReturnsStartGuide from '../start-guides/ReturnsStartGuide';
import ReturnsTranslationsPage from '../returns/ReturnsTranslationsPage';
import PageLoader from '../PageLoader';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import * as permissions from '../../firewall/permissions';

const ReturnPortalRoutes = React.lazy(() => import('./ReturnPortalRoutes' /* webpackChunkName: "returns" */));
const withReturnsAccess = withPermissionRedirect(permissions.RETURNS, '/returns/start-guide');

function ReturnsRoutes() {
  return (
    <Switch>
      <Route path="/returns/return-portals">
        <Suspense fallback={<PageLoader />}>
          <ReturnPortalRoutes />
        </Suspense>
      </Route>
      <Route exact path="/returns/translations" component={withReturnsAccess(ReturnsTranslationsPage)} />
      <Route exact path="/returns/start-guide" component={ReturnsStartGuide} />
      <Redirect exact from="/returns" to="/returns/return-portals" />
      <Route component={Error404} />
    </Switch>
  );
}

export default ReturnsRoutes;
