import React from 'react';

import SearchClaimInfoStep from '../common/SearchClaimInfoStep';
import PartialDamageStepContainer from './PartialDamageStepContainer';

function PartialDamageSearchClaimInfoStep(props) {
  return <SearchClaimInfoStep {...props} as={PartialDamageStepContainer} />;
}

export default React.memo(PartialDamageSearchClaimInfoStep);
