import container from '../container';
import { cleanWidgetId } from './widgetSettings';

export function getEmbeddedWidgets(shopId) {
  return (dispatch) => {
    dispatch(requestEmbeddedWidgets());
    container.api
      .embeddedWidgets(shopId)
      .then((data) => dispatch(receivedEmbeddedWidgets({ shopId, data })))
      .catch((error) => dispatch(errorEmbeddedWidgets(error)));
  };
}

export function requestEmbeddedWidgets() {
  return { type: 'EMBEDDED_WIDGETS_REQUEST' };
}

export function receivedEmbeddedWidgets(data) {
  return { type: 'EMBEDDED_WIDGETS_SUCCESS', data };
}

export function errorEmbeddedWidgets(error) {
  return { type: 'EMBEDDED_WIDGETS_ERROR', error };
}

export function createEmbeddedWidget(shop, name, language) {
  return (dispatch) => {
    dispatch(requestEmbeddedWidgets());
    return container.api
      .createEmbeddedWidget({ shop, name, language })
      .then((data) => {
        container.history.push(`/tracking/embedded-widgets/${data.id}/edit`);
        return { data };
      })
      .catch((error) => {
        dispatch(errorEmbeddedWidgets(error));
        return { error };
      });
  };
}

export function duplicateEmbeddedWidget(embeddedWidgetId, newData, shop) {
  return container.api
    .getEmbeddedWidgetSettings(embeddedWidgetId)
    .then((trackingPage) => cleanWidgetId(trackingPage))
    .then((trackingPage) => {
      const { settings, widgets } = trackingPage;

      const { name, language } = newData;

      const duplicated = {
        shop,
        name,
        language,
        settings,
        widgets,
      };

      return container.api.createEmbeddedWidget(duplicated);
    });
}
