import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStateMachine, Action } from 'react-automata';

import { widgetSettingsSelector } from '../../reducers/selectors';
import { getTrackingPageSettings, updateTrackingPageSettings } from '../../actions/widgetSettings';
import { publishTrackingPageAction } from '../../actions/publishActions';
import PageWithTitle from '../Wizard/PageWithTitle';
import Button from '../common/Button';
import Wizard from '../Wizard';
import ConfirmationModal from '../modal/ConfirmationModal';
import Domain from '../../icons/artwork/domain.svg';
import PublishSuccess from './PublishSuccess';
import Blockquote from './Blockquote'

import './PublishTrackingPage.scss';

const chooseUrlStates = {
  initial: 'open',
  states: {
    open: {
      on: { PUBLISH: 'publishing' },
    },
    publishing: {
      onEntry: ['doPublish'],
      on: {
        PUBLISHED: [
          { target: 'conflict', cond: (data) => data.conflict },
        ]
      },
    },
    conflict: {
      onEntry: ['askConfirmation'],
      on: { CONFIRM: 'forcePublishing' }
    },
    forcePublishing: {
      onEntry: ['doForcePublish'],
    }
  }
};

const stateChart = {
  key: 'publishTrackingPage',
  initial: 'chooseUrl',
  states: {
    chooseUrl: {
      on: {
        PUBLISHED: [
          { target: 'publishSuccess', cond: (data) => data.success },
        ],
        BACK: 'exit',
        CLOSE: 'exit',
      },
      ...chooseUrlStates,
    },
    publishSuccess: {
      on: { CLOSE: 'exit' },
    },
    exit: {
      onEntry: ['goBack'],
    }
  },
};

const trackingPageUrl = process.env.TRACKING_PAGE_URL;

class PublishTrackingPage extends React.PureComponent {

  componentDidMount() {
    if (!this.props.loaded) {
      this.props.getTrackingPageSettings();
    }
  }

  publish(force = false) {
    return this.props.updateTrackingPage()
      .then(() => this.props.publishTrackingPage(force))
      .then(data => this.props.transition('PUBLISHED', data));
  }

  doPublish = () => this.publish();
  doForcePublish = () => this.publish(true);

  goBack = () => {
    this.props.history.push(this.props.editorUrl);
  };

  trackingPageUrl = () => {
    return `${trackingPageUrl}${this.props.trackingPageHash}/<orderId>`
  };

  onBack = () => this.props.transition('BACK');
  onClose = () => this.props.transition('CLOSE');
  onPublish = () => this.props.transition('PUBLISH');
  onConfirm = () => this.props.transition('CONFIRM');

  getCurrentStep(state) {
    if (typeof state.value === 'string') {
      return state.value;
    }
    return Object.keys(state.value)[0];
  }

  // NOTE: link generation must be synced with TrackingUrlGenerator
  render() {
    const {
      machineState,
    } = this.props;

    const step = this.getCurrentStep(machineState);
    const onBack = step !== 'publishSuccess' ? this.onBack : null;

    return (
      <React.Fragment>
        <Wizard selected={step} onBack={onBack} onClose={this.onClose}>
          <PageWithTitle
            key="chooseUrl"
            title="Choose a URL"
            secondaryText="You're almost done!"
          >
            <Domain styleName="domainIcon"/>
            <Blockquote>
              {this.trackingPageUrl()}
            </Blockquote>
            <Button onClick={this.onPublish} primary>
              Publish
            </Button>
          </PageWithTitle>
          <PublishSuccess
            key="publishSuccess"
            trackingPageUrl={this.trackingPageUrl()}
            goToEditor={this.onClose}
          />
        </Wizard>
        <Action is="askConfirmation">
          <ConfirmationModal
            show={true}
            title="Publishing tracking page"
            message="You already have a tracking page with the same localization. Do you want to proceed?"
            onCancel={this.onClose}
            onOK={this.onConfirm}
          />
        </Action>
      </React.Fragment>
    );
  }
}

PublishTrackingPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  published: PropTypes.bool,
  conflict: PropTypes.bool,
  editorUrl: PropTypes.string.isRequired,
  publishTrackingPage: PropTypes.func.isRequired,
  updateTrackingPage: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    hash,
    loaded,
  } = widgetSettingsSelector(state);

  const trackingPageId = ownProps.match.params.id;  

  return {
    trackingPageHash: hash,
    editorUrl: `/tracking/tracking-pages/${trackingPageId}/edit`,
    loaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const trackingPageId = ownProps.match.params.id;

  return {
    getTrackingPageSettings: () => dispatch(getTrackingPageSettings(trackingPageId)),
    publishTrackingPage: (...args) => publishTrackingPageAction(trackingPageId, ...args),
    updateTrackingPage: () => dispatch(updateTrackingPageSettings(trackingPageId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withStateMachine(stateChart)(PublishTrackingPage)
);