import React from 'react';
import PropTypes from 'prop-types';

import SortDropdownButton from './SortDropdownButton';
import MenuItem from '../../common2/DropdownButton/MenuItem';
import VectorGroupAscIcon from '../../../icons/vectorGroupAsc.svg';
import VectorGroupDescIcon from '../../../icons/vectorGroupDesc.svg';
import { Locale } from '../../Translate';

const sortOptions = [
  {
    sortBy: 'createdAt',
    sortDir: 'DESC',
    label: 'Submission date (New to old)',
  },
  {
    sortBy: 'createdAt',
    sortDir: 'ASC',
    label: 'Submission date (Old to new)',
  },
  {
    sortBy: 'netValue',
    sortDir: 'DESC',
    label: 'Claim value (High to low)',
  },
  {
    sortBy: 'netValue',
    sortDir: 'ASC',
    label: 'Claim value (Low to high)',
  },
];

const icons = {
  DESC: VectorGroupDescIcon,
  ASC: VectorGroupAscIcon,
};

function ClaimsSort({ sortBy, sortDir, onSort }) {
  const selectedId = getOptionId(sortBy, sortDir);

  return (
    <SortDropdownButton icon={icons[sortDir]}>
      {sortOptions.map(({ sortBy, sortDir, label }) => {
        const id = getOptionId(sortBy, sortDir);
        return (
          <MenuItem key={id} selected={id === selectedId} onClick={() => onSort({ sortBy, sortDir })}>
            <Locale>{label}</Locale>
          </MenuItem>
        );
      })}
    </SortDropdownButton>
  );
}

function getOptionId(sortBy, sortDir) {
  return `${sortBy}-${sortDir}`;
}

ClaimsSort.propTypes = {
  sortBy: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default ClaimsSort;
