import React from 'react';
import PropTypes from 'prop-types';
import PageWithTitle from '../Wizard/PageWithTitle';
import InstallCodeSelectPanel from './InstallCodeSelectPanel';
import TechnicalIcon from '../../icons/artwork/technical.svg';
import AskHelpIcon from '../../icons/artwork/ask-help.svg';

import './InstallCodeSelectPage.scss';

const InstallCodeSelectPage = (props) => {
  const {
    onInstallClick,
    onHelpClick
  } = props;

  return (
    <PageWithTitle title="How technical are you?" secondaryText="Install the Sendwise code">
      <div styleName="select">
        <InstallCodeSelectPanel onClick={onInstallClick}
          title="I'll install the code"
          text="You'll need access to the code of your platform"
        >
          <TechnicalIcon />
        </InstallCodeSelectPanel>
        <InstallCodeSelectPanel onClick={onHelpClick}
          title="Ask teammate for help"
          text="We’ve already written the email with instructions"
        >
          <AskHelpIcon />
        </InstallCodeSelectPanel>
      </div>
    </PageWithTitle>
  );
};

InstallCodeSelectPage.propTypes = {
  onInstallClick: PropTypes.func,
  onHelpClick: PropTypes.func
};

export default InstallCodeSelectPage;