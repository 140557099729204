import React, { useState } from 'react';
import Tabs from '../../common2/Tabs';
import Switch from '../../Wizard/Switch';

function StartGuideTabs(props) {
  const {
    tabs,
    children,
  } = props;

  const [selectedTab, setSelectedTab] = useState(tabs[0].id);

  return (
    <React.Fragment>
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onChange={setSelectedTab}
      />
      <Switch selected={selectedTab}>
        {children}
      </Switch>
    </React.Fragment>
  );
}

export default React.memo(StartGuideTabs);