import React from 'react';

import SearchClaimInfoStep from '../common/SearchClaimInfoStep';
import WholeDamageStepContainer from './WholeDamageStepContainer';

function WholeDamageSearchClaimInfoStep(props) {
  return <SearchClaimInfoStep {...props} as={WholeDamageStepContainer} showDeliveryInfo />;
}

export default React.memo(WholeDamageSearchClaimInfoStep);
