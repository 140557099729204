import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error404 from '../error-pages/Error404';
import ChatbotPage from '../../chatbot/ChatbotPage';
import ChatbotStartGuidePage from '../../chatbot/ChatbotStartGuide';
import { CHATBOT_PERMISSION } from '../../firewall/permissions';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';

const ChatbotWithAccess = withPermissionRedirect(CHATBOT_PERMISSION, '/chatbot/start-guide')(ChatbotPage);

function ChatbotRoutes() {
  return (
    <Switch>
      <Route exact path="/chatbot" component={ChatbotWithAccess} />
      <Route exact path="/chatbot/start-guide" component={ChatbotStartGuidePage} />
      <Route component={Error404} />
    </Switch>
  );
}

export default ChatbotRoutes;
