import React from 'react';

import PartialDamageStepContainer from './PartialDamageStepContainer';
import ShippingInfoStep from '../common/ShippingInfoStep';

function PartialDamageShippingInfoStep(props) {
  return (
    <ShippingInfoStep
      {...props}
      as={PartialDamageStepContainer}
      showDeliveryInfo
    />
  );
}

export default React.memo(PartialDamageShippingInfoStep);