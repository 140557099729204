import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageContainer from '../pagecontainer/PageContainer';
import * as permissions from '../../firewall/permissions';
import Locale from '../Translate/Locale';
import { buildSortedMenuItems } from './buildSortedMenuItems';
import { selectAnalyticsDashboards } from '../../reducers/analyticsDashboardsReducer';

function getMenuItems(dashboards) {
  const countryViewItems = dashboards.filter((dashboard) => dashboard.id.startsWith('country_view'));
  const items = dashboards.filter((dashboard) => !dashboard.id.startsWith('country_view'));
  const dashboardsItems = items.filter((dashboard) => dashboard.type === 'dashboard');
  const looksItems = items.filter((dashboard) => dashboard.type === 'look');

  return [
    {
      id: 'dashboards',
      title: 'Dashboards',
      children: buildSortedMenuItems(dashboardsItems),
      permission: permissions.ANALYTICS_PERMISSION,
    },
    {
      id: 'reports',
      title: 'Reports',
      children: buildSortedMenuItems(looksItems),
      permission: permissions.ANALYTICS_PERMISSION,
    },
    ...(countryViewItems.length > 0
      ? [
          {
            id: 'country-view',
            title: 'Country View',
            children: buildSortedMenuItems(countryViewItems),
            permission: permissions.ANALYTICS_PERMISSION,
          },
        ]
      : []),
    {
      id: '/analytics/explore',
      title: 'Explore analytics',
      link: '/analytics/explore',
      permission: permissions.ANALYTICS_EXPLORE,
    },
    {
      id: 'start-guide',
      title: 'Start guide',
      link: '/analytics/start-guide',
    },
  ];
}

function AnalyticsPage(props) {
  const { children, ...pageProps } = props;
  const { dashboards } = useSelector(selectAnalyticsDashboards);

  const menuItems = React.useMemo(() => getMenuItems(dashboards), [dashboards]);

  return (
    <PageContainer {...pageProps} menuTitle="Analytics" menuItems={menuItems} collapsible>
      {children || (
        <h1>
          <Locale>Analytics dashboard</Locale>
        </h1>
      )}
    </PageContainer>
  );
}

export default React.memo(AnalyticsPage);
