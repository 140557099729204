import React from 'react';
import { Route, Switch } from 'react-router-dom';

import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import ShopContentLibraryPage from '../ShopContentLibraryPage/ShopContentLibraryPage';
import ShopContentLibraryEditPage from '../ShopContentLibraryPage/ShopContentLibraryEditPage';
import eventTypeEnum from '../ShopContentLibraryPage/eventTypeEnum';
import * as permissions from '../../firewall/permissions';
import Error404 from '../error-pages/Error404';
import { Redirect } from 'react-router';

const withShopContentLibraryAccess = withPermissionRedirect(permissions.SHOP_CONTENT_LIBRARY_PERMISSION);

// We do not want to create a new page component for each route render because specific component handle url changes
const ShopContentLibraryEditPageWithAccess = withShopContentLibraryAccess(ShopContentLibraryEditPage);
const ShopContentLibraryPageWithAccess = withShopContentLibraryAccess(ShopContentLibraryPage);

const ShopContentLibraryWithOrderEvents = withEventType(ShopContentLibraryPageWithAccess, eventTypeEnum.ORDER);
const ShopContentLibraryWithShipmentEvents = withEventType(ShopContentLibraryPageWithAccess, eventTypeEnum.SHIPMENT);
const ShopContentLibraryWithTimelineStates = withEventType(ShopContentLibraryPageWithAccess, eventTypeEnum.TIMELINE);

const ShopContentLibraryEditPageWithOrderEvents = withEventType(
  ShopContentLibraryEditPageWithAccess,
  eventTypeEnum.ORDER
);
const ShopContentLibraryEditPageWithShipmentEvents = withEventType(
  ShopContentLibraryEditPageWithAccess,
  eventTypeEnum.SHIPMENT
);
const ShopContentLibraryEditPageWithTimelineStates = withEventType(
  ShopContentLibraryEditPageWithAccess,
  eventTypeEnum.TIMELINE
);

const ContentLibraryRoutes = () => (
  <Switch>
    <Route exact path="/content-library/order-events" component={ShopContentLibraryWithOrderEvents} />
    <Route exact path="/content-library/shipment-events" render={ShopContentLibraryWithShipmentEvents} />
    <Route exact path="/content-library/timeline-states" render={ShopContentLibraryWithTimelineStates} />
    <Route
      exact
      path="/content-library/order-events/:language/:ticketTypeId(\d+)/edit"
      component={ShopContentLibraryEditPageWithOrderEvents}
    />
    <Route
      exact
      path="/content-library/shipment-events/:language/:ticketTypeId(\d+)/edit"
      component={ShopContentLibraryEditPageWithShipmentEvents}
    />
    <Route
      exact
      path="/content-library/timeline-states/:language/:ticketTypeId(\d+)/edit"
      component={ShopContentLibraryEditPageWithTimelineStates}
    />
    <Redirect exact from="/content-library" to="/content-library/order-events" />
    <Route component={Error404} />
  </Switch>
);

function withEventType(Component, eventType) {
  return (props) => <Component {...props} eventType={eventType} />;
}

export default withPermissionRedirect(permissions.TOUCHPOINTS_PERMISSION)(ContentLibraryRoutes);
