import React from 'react';

import Tooltip from '../common/Tooltip';
import Locale from '../Translate/Locale';
import NavItem from './NavItem';

function NavItemWithTooltip(props) {
  const {
    tooltipText,
    id,
    selected,
  } = props;

  return (
    <React.Fragment>
      <NavItem data-tip data-for={id} data-test-id={tooltipText} selected={selected}> {props.children} </NavItem>
      <Tooltip id={id} position="right" type="dark">
        <Locale>{tooltipText || id}</Locale>
      </Tooltip>
    </React.Fragment>
  );
}

export default React.memo(NavItemWithTooltip);