import React from 'react';

import ShippingInfoStep from '../common/ShippingInfoStep';
import InvestigationStepContainer from './InvestigationStepContainer';

function InvestigationShippingInfoStep(props) {
  return (
    <ShippingInfoStep
      {...props}
      as={InvestigationStepContainer}
    />
  );
}

export default React.memo(InvestigationShippingInfoStep);