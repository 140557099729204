import React from 'react';

import PartialDamageStepContainer from './PartialDamageStepContainer';
import WarehouseDeliveryConfirmationStep from '../common/WarehouseDeliveryConfirmationStep';

function PartialDamageWarehouseDeliveryConfirmationStep(props) {
  return <WarehouseDeliveryConfirmationStep {...props} as={PartialDamageStepContainer} showDeliveryInfo />;
}

export default React.memo(PartialDamageWarehouseDeliveryConfirmationStep);
