import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { datadogRum } from '@datadog/browser-rum';
import { I18nextProvider, translate } from 'react-i18next';
import { Provider } from 'react-redux';
import { FlagProvider } from '@unleash/proxy-client-react';

import ApiError from './components/ApiError';
import AppRoutes from './components/AppRoutes';
import ErrorBoundary from './components/ErrorBoundary';
import container from './container';
import { config } from './unleash';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-55440238-8');

  container.history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  datadogRum.init({
    applicationId: 'dac9f969-7ae4-44d8-9830-edf3a0b379ba',
    clientToken: 'pub4473ee247b8cb02b1d7070e95a09d6b3',
    site: 'datadoghq.eu',
    service: '7s-portal',
    env: process.env.DATADOG_ENV,
    version: process.env.SENDWISE_APP_VERSION,
    sessionSampleRate: 5,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const { store } = container;
store.dispatch({ type: 'INIT' });

ReactDOM.render(
  <FlagProvider config={config}>
    <I18nextProvider i18n={container.i18n}>
      <ErrorBoundary>
        <Provider store={store}>
          <ApiError>
            <AppRoutes />
          </ApiError>
        </Provider>
      </ErrorBoundary>
    </I18nextProvider>
  </FlagProvider>,
  document.getElementById('root')
);
