import React from 'react';

import WarehouseDeliveryConfirmationStep from '../common/WarehouseDeliveryConfirmationStep';
import MissingItemStepContainer from './MissingItemStepContainer';

function MissingItemWarehouseDeliveryConfirmationStep(props) {
  return <WarehouseDeliveryConfirmationStep {...props} as={MissingItemStepContainer} showDeliveryInfo />;
}

export default React.memo(MissingItemWarehouseDeliveryConfirmationStep);
