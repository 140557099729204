import React from 'react';
import PropTypes from 'prop-types';

import container from '../../container';
import Wizard from '../Wizard';
import InstallCodeSelectPage from './InstallCodeSelectPage';
import InstallCodeTagPage from './InstallCodeTagPage';
import InstallCodeHelpPage from './InstallCodeHelpPage';
import InstallCodeHelpFinishPage from './InstallCodeHelpFinishPage';
import { getEmbeddedWidgetSettings, publishEmbeddedWidget } from '../../actions/widgetSettings';
import { connect } from 'react-redux';
import { widgetSettingsSelector } from '../../reducers/selectors';
import { withTranslation } from 'react-i18next';
import { getUrlType } from '../../utils/urlPathHelper';

const urls = {
  select: '/tracking/embedded-widgets/:id/install-code',
  installCode: '/tracking/embedded-widgets/:id/install-code/install-code',
  sendInstructions: '/tracking/embedded-widgets/:id/install-code/send-instructions',
  sendInstructionsFinish: '/tracking/embedded-widgets/:id/install-code/send-instructions/finish',
};

const successMessage =
  'Once your Sendwise tag is sucessfully installed, we will push the data.' +
  ' In the meantime there is so much to explore';
const emailMessage =
  'Your help is requested to install your Sendwise embedded widget. \n' +
  "We've made the installing process as easy as possible for you. " +
  'Use the button below to read our documentation. \n' +
  '\n' +
  'Your unique Embedded widget ID: {{widgetId}}';

export class InstallCodePage extends React.PureComponent {
  state = {
    recipients: [],
  };

  componentDidMount() {
    this.props.getWidgetSettings();
  }

  redirectToRelativePath(path) {
    const url = `/tracking/embedded-widgets/${this.props.embeddedWidgetId}/install-code${path}`;
    this.props.history.push(url);
  }

  onSelectInstallCode = () => {
    this.redirectToRelativePath('/install-code');
  };

  onSelectHelp = () => {
    this.redirectToRelativePath('/send-instructions');
  };

  onSendInstructions = (recipients, sendCopyToMe, subject, message) => {
    const options = {
      recipients,
      sendCopyToMe,
      subject,
      message,
    };

    container.api.sendEmbeddedWidgetInstruction(this.props.embeddedWidgetId, options).then(() => {
      this.redirectToRelativePath('/send-instructions/finish');
      this.setState({ recipients });
    });
  };

  onPublish = () => {
    this.props.publishEmbeddedWidget().then(() => this.onClose());
  };

  goToSelectStep = () => {
    this.redirectToRelativePath('');
  };

  onClose = () => {
    this.props.history.push(`/tracking/embedded-widgets/${this.props.embeddedWidgetId}/edit`);
  };

  render() {
    const { recipients } = this.state;

    const { location, hash } = this.props;

    const step = getUrlType(location.pathname, urls);
    const widgetInstallEmailMessage = this.props.t(emailMessage, { widgetId: hash });

    // TODO: component needs improvements to get rid of conditions and might be local state
    const onBack = step === 'installCode' || step === 'sendInstructions' ? this.goToSelectStep : null;
    const onClose = step === 'sendInstructionsFinish' ? null : this.onClose;

    return (
      <Wizard selected={step} onBack={onBack} onClose={onClose}>
        <InstallCodeSelectPage key="select" onInstallClick={this.onSelectInstallCode} onHelpClick={this.onSelectHelp} />
        <InstallCodeTagPage key="installCode" hash={hash} onButtonClick={this.onPublish} />
        <InstallCodeHelpPage
          key="sendInstructions"
          message={widgetInstallEmailMessage}
          onSendClick={this.onSendInstructions}
        />
        <InstallCodeHelpFinishPage
          key="sendInstructionsFinish"
          recipients={recipients}
          buttonLink="/tracking/embedded-widgets"
          buttonName="Back to dashboard"
          message={successMessage}
        />
      </Wizard>
    );
  }
}

InstallCodePage.propTypes = {
  t: PropTypes.func.isRequired,
  embeddedWidgetId: PropTypes.string.isRequired,
  getWidgetSettings: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  hash: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const { hash } = widgetSettingsSelector(state);

  return {
    hash,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const embeddedWidgetId = ownProps.match.params.id;

  return {
    getWidgetSettings: () => dispatch(getEmbeddedWidgetSettings(embeddedWidgetId)),
    publishEmbeddedWidget: () => dispatch(publishEmbeddedWidget(embeddedWidgetId)),
    embeddedWidgetId,
  };
}

export const InstallCodePageConnected = connect(mapStateToProps, mapDispatchToProps)(InstallCodePage);

export default withTranslation()(InstallCodePageConnected);
