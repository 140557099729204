import React from 'react';

import { Menu } from '../modal/Menu';
import Overlay from '../modal/Overlay';
import NavItem from './NavItem';
import { Account } from './navIcons';

import './UserButton.scss';

export default class UserButton extends React.Component {
  state = {};

  onCloseMenu = () => this.setState({showMenu: false});
  onButtonClick = () => this.setState({showMenu: !this.state.showMenu});

  render() {
    const { showMenu } = this.state;

    return (
      <NavItem
        styleName="container"
        selected={showMenu}
      >
        <div onClick={this.onButtonClick} data-test-id="user-button">
          {Account}
        </div>
        { showMenu && (
          <React.Fragment>
            <div styleName="menu">
              <Menu onClose={this.onCloseMenu}>
                {this.props.children}
              </Menu>
            </div>
            <Overlay onClick={this.onCloseMenu} />
          </React.Fragment>
        ) }
      </NavItem>
    );
  }
}
