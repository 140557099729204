import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDashboardSSO } from '../../actions/dashboard';
import Iframe from '../common/Iframe';

export class LookerDashboard extends React.PureComponent {
  state = {};

  componentDidMount() {
    const { getDashboardSSO, dashboardId, type } = this.props;
    getDashboardSSO(dashboardId, type);
  }

  onLoad = () => {
    this.setState({ iframeIsLoaded: true });
  };

  render() {
    const { ssoUrl, isLoading, loaded, error } = this.props;
    const { iframeIsLoaded } = this.state;
    const showLoader = isLoading || (!error && !iframeIsLoaded);

    return <Iframe onLoad={this.onLoad} pageUrl={ssoUrl} showLoader={showLoader} showIframe={loaded} />;
  }
}

LookerDashboard.defaultProps = {
  type: 'dashboard',
};

LookerDashboard.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    getDashboardSSO: (dashboardId, type) => dispatch(getDashboardSSO(dashboardId, type)),
  };
}

function mapStateToProps(state) {
  const {
    lookerDashboard: { id, name, ssoUrl, error, isLoading, loaded },
  } = state;

  return {
    id,
    name,
    ssoUrl,
    error,
    isLoading,
    loaded,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LookerDashboard);
