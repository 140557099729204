import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import Wizard, { SwitchWithPagination } from '../Wizard';
import WidgetLanguagePage from './WidgetLanguagePage';
import ChooseNameStep from '../Wizard/ChooseNameStep';
import { selectedShopIdSelector } from '../../reducers/selectors';
import { createEmbeddedWidget } from '../../actions/embeddedWidgets';

const steps = {
  name: 'choose-name',
  language: 'choose-language',
};

const urls = {
  base: '/tracking/embedded-widgets/create',
  language: '/tracking/embedded-widgets/create/choose-language',
  name: '/tracking/embedded-widgets/create/choose-name',
};

export class CreateWidgetPage extends React.PureComponent {
  state = {};

  onClose = () => {
    this.props.history.push('/tracking/embedded-widgets');
  };

  onLanguageChange = (language) => {
    this.setState({ language }, () => {
      this.props.history.push(urls.name);
    });
  };

  onNameChange = (name) => {
    this.props.createEmbeddedWidget(
      this.props.shopId,
      name,
      this.state.language,
    );
  };

  render() {
    const {
      step,
      history,
    } = this.props;

    const {
      language,
    } = this.state;

    if (step === steps.name && !language) {
      return <Redirect to={urls.language} />;
    }

    const onBack = step === steps.language ? null : history.goBack;

    return (
      <Wizard
        onBack={onBack}
        onClose={this.onClose}
      >
        <SwitchWithPagination selected={step}>
          <WidgetLanguagePage
            key={steps.language}
            subheader="Create a new embedded widget"
            buttonText="Name my embedded widget"
            language={language}
            onSelectLanguage={this.onLanguageChange}
          />
          <ChooseNameStep
            key={steps.name}
            entityName="embedded widget"
            onSubmit={this.onNameChange}
          />
        </SwitchWithPagination>
      </Wizard>
    );
  }
}

CreateWidgetPage.defaultProps = {
  step: steps.language,
  createEmbeddedWidget: () => {},
};

CreateWidgetPage.propTypes = {
  createEmbeddedWidget: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    shopId: selectedShopIdSelector(state),
    step: ownProps.match.params.step,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createEmbeddedWidget: (...args) => dispatch(createEmbeddedWidget(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWidgetPage);
