import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../common/Checkbox';
import URLInput from '../../../common/URLInput';

class ImageSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = getStateFromProps(props);
  }

  componentWillReceiveProps(props) {
    this.setState(getStateFromProps(props));
  };

  onUseMobileChange = (e) => {
    const newState = { useMobile: e.target.checked };
    this.setState(newState);

    if (!newState.useMobile) {
      this.props.onChange({ imageUrl: this.props.imageUrl, mobileImageUrl: null });
    }
  };

  onImageUrlChange = (e) => {
    this.props.onChange({ imageUrl: e.target.value, mobileImageUrl: this.props.mobileImageUrl });
  };

  onMobileImageUrlChange = (e) => {
    this.props.onChange({ imageUrl: this.props.imageUrl, mobileImageUrl: e.target.value });
  };

  render() {
    const {
      imageUrl,
      mobileImageUrl,
      label,
      mobileLabel,
      showMobile,
    } = this.props;

    const imageUrlPlaceholder = 'http://example.com/image.jpg';
    const { useMobile = false } = this.state;

    return (
      <React.Fragment>
        <URLInput
          value={imageUrl}
          label={label}
          placeholder={imageUrlPlaceholder}
          onChange={this.onImageUrlChange}
        />
        { showMobile &&
          <Checkbox checked={useMobile} onChange={this.onUseMobileChange}>
            Use different image for mobile
          </Checkbox>
        }
        { useMobile &&
        <URLInput
          value={mobileImageUrl}
          label={mobileLabel}
          placeholder={imageUrlPlaceholder}
          onChange={this.onMobileImageUrlChange}
        />
        }
      </React.Fragment>
    );
  }
}

function getStateFromProps(props) {
  const { mobileImageUrl } = props;

  if (mobileImageUrl && mobileImageUrl.length > 0) {
    return { useMobile: true };
  }

  return {};
}

ImageSettings.defaultProps = {
  onChange: () => {},
  label: 'Image Url',
  mobileLabel: 'Mobile Image Url',
  showMobile: true,
};

ImageSettings.propTypes = {
  imageUrl: PropTypes.string,
  mobileImageUrl: PropTypes.string,
  label: PropTypes.string,
  mobileLabel: PropTypes.string,
  onChange: PropTypes.func,
  showMobile: PropTypes.bool,
};

export default ImageSettings;
