import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../../Translate/Locale';

import StatusLabel from './StatusLabel';

import './InputLabel.scss';

class InputLabel extends React.PureComponent {
  render() {
    const {
      label,
      error,
      children,
    } = this.props;

    return (
      <div styleName="input-container">
        <div styleName="labels-container">
          { label && <div styleName="label"><Locale>{label}</Locale></div> }
          { error && (
            <div styleName="error">
              <StatusLabel status="error">
                {error}
              </StatusLabel>
            </div>
          )}
        </div>
        {children}
      </div>
    );
  }
}

InputLabel.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.element,
};

export default InputLabel;