import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Locale from '../../Translate/Locale';
import useFocus from '../../../hooks/useFocus';
import { useTranslation } from 'react-i18next';

import SearchIcon from '../../../icons/parcel-center-search.svg';

import searchTypes, { inputPlaceholders } from './searchTypes';
import './SearchInput.scss';
import Tabs from './Tabs/Tabs';

function SearchInput(props) {
  const { searchType, onChangeType, onChange, onClear, onSearch, placeholder, ...inputProps } = props;

  const inputRef = useRef(null);
  const [focused, callbacks] = useFocus(false);
  const { t } = useTranslation();

  const handleChange = (e) => onChange(e.target.value, props.name);

  const handleClear = (e) => {
    e.stopPropagation();
    inputRef.current.focus(); // we need to keep focus in input
    onClear(props.name);
  };

  const handleSearch = () => {
    onSearch(props.value, searchType, props.name);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <Tabs selectedTab={searchType} tabs={searchTypes} onChange={onChangeType} />
      <div styleName={classNames('container', { focused })}>
        <input
          type="text"
          styleName="input"
          name="parcel-finder-search"
          ref={inputRef}
          autoFocus
          placeholder={t(inputPlaceholders[searchType])}
          {...inputProps}
          {...callbacks}
          onKeyPress={handleKeyPress}
          onChange={handleChange}
        />
        {props.value && (
          <React.Fragment>
            <button styleName="clear-button" aria-label="Clear" onClick={handleClear}>
              <Locale>Clear</Locale>
            </button>
          </React.Fragment>
        )}
        <button styleName="search-button" aria-label="Search" onClick={handleSearch}>
          <SearchIcon styleName="icon" />
        </button>
      </div>
    </>
  );
}

SearchInput.defaultProps = {
  onChange: () => {},
  onClear: () => {},
  onSearch: () => {},
  onChangeType: () => {},
};

SearchInput.propTypes = {
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onSearch: PropTypes.func,
  onChangeType: PropTypes.func,
};

export default React.memo(SearchInput);
