import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';

import Select from '../../../common2/Select';
import ColumnsLayout from '../../../common/layout/ColumnsLayout';
import CarrierSelect from './CarrierSelect';
import DateInput from '../../../common2/DateInput';
import Locale from '../../../Translate/Locale';
import SidePanelContentSection from '../../../common2/side-panel/SidePanelContentSection';
import { useClaimCarrierSelectOptions } from './useClaimCarrierSelectOptions';
import Switch from '../../../Wizard/Switch';
import ClaimStepClosableMessage from './ClaimStepClosableMessage';
import { isDeadlinesEnabled } from '../claimsHelpers';
import { useYupValidation } from '../../../../hooks/useYupValidation';
import useOnFormChange from '../../../../hooks/useOnFormChange';

const shipmentTypeOptions = [
  { value: 'outbound', label: 'Outbound' },
  { value: 'return', label: 'Return' },
  { value: 'undeliverable_or_postal_return', label: 'Undeliverable / Postal Return' },
];

export const schema = yup.object().shape({
  type: yup.string().required(),
  shipmentType: yup.string().required().label('Shipment type'),
  carrierCode: yup.string().required().label('Carrier'),
  carrierCountryCode: yup.string().required().label('Carrier country'),
  firstHubScanDate: yup
    .date()
    .nullable()
    .when(['type'], {
      is: (type) => type === 'investigation',
      then: (schema) => schema.required(),
    })
    .label('First hub scan date'),
  postalReturnDate: yup
    .date()
    .nullable()
    .when(['type', 'shipmentType'], {
      is: (type, shipmentType) => type === 'investigation' && shipmentType === 'undeliverable_or_postal_return',
      then: (schema) => schema.required(),
    })
    .label('Postal return date'),
  deliveryDate: yup
    .date()
    .nullable()
    .when(['type'], {
      is: (type) => type !== 'investigation',
      then: (schema) => schema.required(),
    })
    .label('Delivery date'),
  postalReturnDeliveryDate: yup.date().nullable().label('Delivery date'),
});

function DeadlineShippingInfoStep(props) {
  const { claim, carriers, as: Container, onNext, onBack, onChange, ...restProps } = props;
  const { type, shipmentType, carrierCode, carrierCountryCode } = claim;

  const { carrierOptions, carrierCountryOptions } = useClaimCarrierSelectOptions({
    carrierCode: claim.carrierCode,
    carrierCountryCode: claim.carrierCountryCode,
    carriers,
  });

  const onShipmentTypeChange = useCallback(
    (shipmentType) =>
      onChange({
        shipmentType,
        isReturnShipment: shipmentType === 'return',
        isDelivered: shipmentType === 'outbound', // we set it true to force user enter it later
        // reset all dates when changing shipment type to avoid hidden dates which aren't relevant anymore
        firstHubScanDate: null,
        postalReturnDate: null,
        deliveryDate: null,
        postalReturnDeliveryDate: null,
        declarationOfRecipient: [], // if it was already filled on another step, it's not relevant anymore
      }),
    [onChange]
  );

  const onInputChange = useOnFormChange(onChange);

  const onDeliveryDateChange = useCallback((deliveryDate) => onChange({ deliveryDate, isDelivered: !!deliveryDate }), [
    onChange,
  ]);

  const onPostalReturnDeliveryDateChange = useCallback(
    (postalReturnDeliveryDate) => onChange({ postalReturnDeliveryDate, isDelivered: !!postalReturnDeliveryDate }),
    [onChange]
  );

  const { validate, errors } = useYupValidation(schema);

  const onClickNext = () => {
    if (validate(claim)) {
      onNext();
    }
  };

  // TODO: delete this block together with feature toggle
  useEffect(() => {
    if (!isDeadlinesEnabled()) {
      onNext({ replace: true });
    }
  }, []);

  const getDateInputProps = (name) => ({
    name,
    value: claim[name],
    error: errors[name],
    noErrorTranslation: true,
    onChange: onInputChange,
  });

  const selectedShipmentTypeAndFlow = `${
    type === 'investigation' ? 'investigation' : 'damage_or_missing_item'
  }_${shipmentType}`;

  return (
    <Container {...restProps} onNext={onClickNext} onBack={onBack}>
      <ClaimStepClosableMessage intent="danger">
        <Locale>CLAIMS_NO_SHIPMENT_FOUND_ENTER_DEADLINE_INFO</Locale>
      </ClaimStepClosableMessage>
      <SidePanelContentSection heading="Deadline check">
        <ColumnsLayout>
          <Select
            label="Shipment type"
            placeholder="Select shipment type"
            value={shipmentType}
            options={shipmentTypeOptions}
            onChange={onShipmentTypeChange}
            error={errors.shipmentType}
            isRequired
          />
          <div />
          <CarrierSelect
            carriers={carriers}
            carrierCode={carrierCode}
            carrierCountryCode={carrierCountryCode}
            carrierOptions={carrierOptions}
            carrierCountryOptions={carrierCountryOptions}
            errors={errors}
            onChange={onChange}
          />
          <Switch selected={selectedShipmentTypeAndFlow}>
            <React.Fragment key="investigation_outbound">
              <DateInput
                {...getDateInputProps('firstHubScanDate')}
                isRequired
                label="First hub scan date"
                onChange={onInputChange}
              />
            </React.Fragment>
            <React.Fragment key="investigation_return">
              <DateInput
                {...getDateInputProps('firstHubScanDate')}
                isRequired
                label="First hub scan date"
                onChange={onInputChange}
              />
              <DateInput
                {...getDateInputProps('deliveryDate')}
                label="Delivery date at carrier's hub"
                onChange={onDeliveryDateChange}
              />
            </React.Fragment>
            <React.Fragment key="investigation_undeliverable_or_postal_return">
              <DateInput
                {...getDateInputProps('firstHubScanDate')}
                isRequired
                label="First hub scan date"
                onChange={onInputChange}
              />
              <DateInput
                {...getDateInputProps('postalReturnDate')}
                isRequired
                label="Postal return date"
                onChange={onInputChange}
              />
              <DateInput
                {...getDateInputProps('postalReturnDeliveryDate')}
                label="Delivery date at carrier's hub"
                onChange={onPostalReturnDeliveryDateChange}
              />
            </React.Fragment>
            <DateInput
              key="damage_or_missing_item_outbound"
              {...getDateInputProps('deliveryDate')}
              isRequired
              label="Delivery date"
              onChange={onDeliveryDateChange}
            />
            <DateInput
              key="damage_or_missing_item_return"
              {...getDateInputProps('deliveryDate')}
              isRequired
              label="Delivery date"
              helpText="WAREHOUSE_DELIVERY_AS_DELIVERY_DATE_HELP_TEXT"
              onChange={onDeliveryDateChange}
            />
            <DateInput
              key="damage_or_missing_item_undeliverable_or_postal_return"
              {...getDateInputProps('deliveryDate')}
              isRequired
              helpText="WAREHOUSE_DELIVERY_AS_DELIVERY_DATE_HELP_TEXT"
              label="Delivery date"
              onChange={onDeliveryDateChange}
            />
          </Switch>
        </ColumnsLayout>
      </SidePanelContentSection>
    </Container>
  );
}

export default React.memo(DeadlineShippingInfoStep);
