import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';

import Link from '../common/Link';
import { denormalizeUserInfo, denormalizeShop } from '../../models/userInfo';
import selectShop from '../../actions/selectShop';
import ProductMenu from './ProductMenu';
import UserMenu from './UserMenu';
import logoutAction from '../../actions/logout';

import NavItemWithTooltip from './NavItemWithTooltip';

import { Logo, ContentLibrary, ProductUpdates, Support } from './navIcons';

import './Navigation.scss';
import { TOUCHPOINTS_PERMISSION } from '../../firewall/permissions';
import usePermissionAccess from '../../hooks/usePermissionAccess';

export function Navigation(props) {
  const { userInfo, selectedShop, onSelectShop, selectedProduct, onLogout, location } = props;

  const isContentLibrarySelected = matchPath(location.pathname, {
    path: '/content-library',
    exact: false,
  });

  const hasContentLibraryAccess = usePermissionAccess(TOUCHPOINTS_PERMISSION);

  return (
    <div styleName="container">
      <Link to="/home" styleName="logo">
        {Logo}
      </Link>
      <div styleName="product-menu">
        <ProductMenu selectedProduct={selectedProduct} />
      </div>
      <div styleName="secondary-menu">
        {hasContentLibraryAccess && (
          <NavItemWithTooltip
            id="content-library"
            tooltipText="CONTENT_LIBRARY_NAV"
            selected={isContentLibrarySelected}
          >
            <Link styleName="link" to="/content-library">
              {ContentLibrary}
            </Link>
          </NavItemWithTooltip>
        )}
        <NavItemWithTooltip id="product-update" tooltipText="PRODUCT_UPDATES_NAV">
          <a styleName="link" target="_blank" href=" https://app.getbeamer.com/sendwise/en">
            {ProductUpdates}
          </a>
        </NavItemWithTooltip>
        <NavItemWithTooltip id="support-link" tooltipText="SUPPORT_LINK_NAV">
          <a styleName="link" target="_blank" href="https://support.portal.sevensenders.com/support/home">
            {Support}
          </a>
        </NavItemWithTooltip>
        <UserMenu selectedShop={selectedShop} userInfo={userInfo} onSelectShop={onSelectShop} onLogout={onLogout} />
      </div>
    </div>
  );
}

Navigation.defaultProps = {
  onLogout: () => {},
  onSelectShop: () => {},
};

Navigation.propTypes = {
  onSelectShop: PropTypes.func,
  onLogout: PropTypes.func,
  userInfo: PropTypes.object,
  selectedShop: PropTypes.object,
  selectedProduct: PropTypes.string,
  location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    user: { info, entities, selectedShopId },
  } = state;

  return {
    userInfo: denormalizeUserInfo(info, entities),
    selectedShop: denormalizeShop(selectedShopId, entities),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogout: () => dispatch(logoutAction()),
    onSelectShop: (...args) => dispatch(selectShop(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Navigation));
