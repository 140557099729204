import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../common2/Modal';
import ModalHeader from '../../common2/Modal/ModalHeader';
import ModalContent from '../../common2/Modal/ModalContent';
import ModalFooter from '../../common2/Modal/ModalFooter';
import Button from '../../common2/Button/Button';
import RemoveIcon from '../../../icons/remove.svg';
import RichLocale from '../../common2/RichLocale';

import './CancelClaimModal.scss';

function CancelClaimModal(props) {
  const { claim = {}, onClose } = props;

  if (!claim) {
    return null;
  }

  return (
    <Modal show={true} onClose={onClose}>
      <ModalHeader icon={RemoveIcon} intent="danger">
        Are you sure you want to cancel this claim?
      </ModalHeader>
      <ModalContent>
        <RichLocale values={{ trackingCode: claim.trackingCode }}>CLAIM_CANCELLATION_CONFIRMATION_MESSAGE</RichLocale>
      </ModalContent>
      <ModalFooter>
        <Button intent="base" onClick={onClose}>
          Close
        </Button>
        <Button intent="danger" onClick={() => {}}>
          Cancel claim
        </Button>
      </ModalFooter>
    </Modal>
  );
}

CancelClaimModal.propTypes = {
  claim: PropTypes.object,
  onClose: PropTypes.func,
};

export default React.memo(CancelClaimModal);
