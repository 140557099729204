import React from 'react';
import { Redirect } from 'react-router';
import container from '../container';

export function withPermissionRedirect(firewall) {
  return (requiredAccess, redirectPath = '/') => (DecoratedComponent) => (props) => {
    if (firewall.hasAccess(requiredAccess)) {
      return <DecoratedComponent {...props} />;
    }

    return <Redirect to={redirectPath} />;
  };
}

export default withPermissionRedirect(container.firewall);
