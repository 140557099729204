import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SelectLanguage from '../common/SelectLanguage';
import SettingsNavigationHeader from '../SettingsPage/SettingsNavigationHeader';
import PageContainer from '../pagecontainer/PageContainer';
import {
  getContentLibraryMessageByLanguage,
  editContentLibraryMessage,
  resetContentLibraryMessage,
} from '../../actions/contentLibraryActions';
import { contentLibraryFormSelector, selectedShopIdSelector } from '../../reducers/selectors';
import {
  getContentLibraryEventsBaseUrl,
  getMessageEditUrl,
  getMessageEventsTitle,
  messageShape,
} from './contentLibraryHelper';
import ConfirmationModal from '../modal/ConfirmationModal';
import ContentLibraryMessageWithPreview from './ContentLibraryMessageWithPreview';
import LanguageSelectWrapper from './LanguageSelectWrapper';

import './ShopContentLibraryEditPage.scss';

export class ShopContentLibraryEditPage extends React.PureComponent {
  state = {};

  componentDidMount() {
    this.props.getMessageByLanguage(this.props.shopId, this.props.eventType, this.props.ticketTypeId);
  }

  onLanguageChange = (option) => {
    const language = option.value;
    const url = getMessageEditUrl(this.props.eventType, this.props.ticketTypeId, language);

    this.props.history.replace(url);
  };

  onSave = (data) => {
    const {
      shopId,
      message: { id },
      editMessage,
    } = this.props;

    editMessage(id, { shopId, ...data });
  };

  onReset = () => {
    this.setState({ showConfirmation: true });
  };

  onCloseModal = () => {
    this.setState({ showConfirmation: false });
  };

  resetMessage = () => {
    const { message, resetMessage } = this.props;

    resetMessage(message);
    this.onCloseModal();
  };

  render() {
    const { message, ...pageProps } = this.props;

    return (
      <PageContainer {...pageProps}>
        <div styleName="container">
          <SettingsNavigationHeader
            title={message.title}
            backTitle={getMessageEventsTitle(message.type)}
            backLink={getContentLibraryEventsBaseUrl(message.type)}
          >
            <LanguageSelectWrapper>
              <SelectLanguage value={message.language} onChange={this.onLanguageChange} />
            </LanguageSelectWrapper>
          </SettingsNavigationHeader>
          <ContentLibraryMessageWithPreview
            key={message.id}
            message={message}
            onReset={this.onReset}
            onSave={this.onSave}
          />
          <ConfirmationModal
            show={this.state.showConfirmation}
            title="Reset content to default"
            message="CONTENT_LIBRARY_RESET_TO_DEFAULT_CONFIRMATION_MESSAGE_TEXT"
            okText="Reset to default"
            onCancel={this.onCloseModal}
            onOK={this.resetMessage}
          />
        </div>
      </PageContainer>
    );
  }
}

ShopContentLibraryEditPage.defaultProps = {
  getMessageByLanguage: () => {},
};

ShopContentLibraryEditPage.propTypes = {
  shopId: PropTypes.number.isRequired,
  ticketTypeId: PropTypes.number.isRequired,
  message: PropTypes.shape(messageShape),
  language: PropTypes.string,
  getMessageByLanguage: PropTypes.func,
  editMessage: PropTypes.func,
  resetMessage: PropTypes.func,
};

function mapStateToProps(state, { match }) {
  const shopId = selectedShopIdSelector(state);
  const { language } = match.params;
  const ticketTypeId = parseInt(match.params.ticketTypeId, 10);

  const { messageByLanguage } = contentLibraryFormSelector(state);

  const message = Object.values(messageByLanguage).find((msg) => msg.language === language) || {};

  return {
    shopId,
    ticketTypeId,
    language,
    message,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMessageByLanguage: (shopId, type, ticketTypeId) =>
      dispatch(getContentLibraryMessageByLanguage(shopId, type, ticketTypeId)),
    editMessage: (messageId, data) => dispatch(editContentLibraryMessage(messageId, data)),
    resetMessage: (message) => dispatch(resetContentLibraryMessage(message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopContentLibraryEditPage);
