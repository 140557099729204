import React from 'react';

import { getUrlType } from '../../utils/urlPathHelper';
import { Redirect } from 'react-router';

function TouchpointsOverviewRedirect(props) {
  const { pathname, data = [], urls } = props;

  const urlType = getUrlType(pathname, urls);

  if (urlType === 'base' && data.length === 0) {
    return <Redirect to={urls.start} />;
  }

  if (urlType === 'start' && data.length > 0) {
    return <Redirect to={urls.base} />;
  }

  return null;
}

export default React.memo(TouchpointsOverviewRedirect);
