import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectedShopIdSelector } from './selectors';

import container from '../container';
import { selectParcelFinderReturns } from '../selectors/parcelFinderReturnsSelectors';

const initialState = {
  order: null,
  labelsCount: 1,
  returnShipments: [],
  isLoading: false,
};

export const createReturnShipments = createAsyncThunk(
  'parcelFinderReturns/createReturnShipments',
  (_, { getState }) => {
    const state = getState();
    const shopId = selectedShopIdSelector(state);
    const { labelsCount, order } = selectParcelFinderReturns(state);

    return container.touchpointsApi.createReturnShipments(shopId, {
      count: labelsCount,
      orderId: order.id,
    });
  }
);

const slice = createSlice({
  name: 'parcelFinderReturns',
  initialState,
  reducers: {
    initReturn(state, { payload: orders }) {
      state.order = orders;
    },
    cancelReturn() {
      return initialState;
    },
    setLabelsCount(state, { payload: count }) {
      state.labelsCount = count;
    },
  },
  extraReducers: {
    [createReturnShipments.pending](state) {
      state.isLoading = true;
    },
    [createReturnShipments.fulfilled](state, action) {
      state.isLoading = false;
      state.returnShipments = action.payload;
    },
    [createReturnShipments.rejected](state) {
      state.isLoading = false;
    },
  },
});

export const { initReturn, cancelReturn, setLabelsCount } = slice.actions;

export default slice.reducer;
