import React from 'react';
import PropTypes from 'prop-types';
import Button from '../common/Button';
import PageWithTitle from '../Wizard/PageWithTitle';
import HelpOnTheWayIcon from '../../icons/artwork/help-on-the-way.svg';
import Link from '../common/Link';
import Locale from '../Translate/Locale';

import './InstallCodeHelpFinishPage.scss';

class InstallCodeHelpFinishPage extends React.PureComponent {
  render() {
    const {
      message,
      buttonLink,
      buttonName
    } = this.props;

    const emails = this.props.recipients.join(', ');

    return (
      <div>
        <div styleName="help">
          <HelpOnTheWayIcon styleName="helpIcon" />
        </div>
        <PageWithTitle title="Help is on it's way!" secondaryText="Hooray!">
          <div styleName="content">
            <div styleName="feedback">
              <Locale>We've sent the setup guide to</Locale> {emails}
              <br />
              <Locale>{message}</Locale>
            </div>
            <Link to={buttonLink}>
              <Button primary>
                {buttonName}
              </Button>
            </Link>
          </div>
        </PageWithTitle>
      </div>
    );
  }
}

InstallCodeHelpFinishPage.defaultProps = {
  recipients: []
};

InstallCodeHelpFinishPage.propTypes = {
  recipients: PropTypes.arrayOf(PropTypes.string),
  buttonLink: PropTypes.string,
  buttonName: PropTypes.string,
  message: PropTypes.string,
};

export default InstallCodeHelpFinishPage;