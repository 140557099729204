import container from '../container';

export function getTranslatedEmailError(validatedEmails) {
  const allEmails = Object.keys(validatedEmails);
  const invalidEmails = allEmails.filter((email) => !validatedEmails[email]);

  if (allEmails.length === 0) {
    return container.i18n.t('List of emails can not be empty');
  } else if (invalidEmails.length > 0) {
    return container.i18n.t('INVALID_EMAILS_ERROR: {{emails}}', { emails: invalidEmails.join(', ') });
  }
}
