import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import EmbeddedWidgetsPage from '../EmbeddedWidgetsPage';
import CreateWidgetPage from '../CreateWidgetPage';
import EmbeddedWidgetEditor from '../editors/embedded-widget-editor/EmbeddedWidgetEditor';
import InstallCodePage from '../InstallCodePage';
import TrackingPagesPage from '../TrackingPagesPage';
import CreateTrackingPage from '../touchpoints/CreateTrackingPage';
import TrackingPageEditor from '../editors/tracking-page-editor/TrackingPageEditor';
import PublishTrackingPage from '../PublishTrackingPage';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import * as permissions from '../../firewall/permissions';
import Error404 from '../error-pages/Error404';
import TrackingStartGuidePage from '../start-guides/TrackingStartGuidePage';

const withEmbeddedWidgetsAccess = withPermissionRedirect(permissions.EMBEDDED_WIDGETS_PERMISSION, '/tracking/start-guide');
const withTrackingPagesAccess = withPermissionRedirect(permissions.TRACKING_PAGES_PERMISSION, '/tracking/start-guide');
const withTouchpointsAccess = withPermissionRedirect(permissions.TOUCHPOINTS_PERMISSION, '/tracking/start-guide');

const TrackingRoutes = () => (
  <Switch>
    <Route path="/tracking/embedded-widgets" component={EmbeddedWidgetRoutesWithAccess} />
    <Route path="/tracking/tracking-pages" component={TrackingPageRoutesWithAccess} />
    <Route path="/tracking/start-guide" component={TrackingStartGuidePage} />
    <Redirect exact from="/tracking" to="/tracking/tracking-pages" />
  </Switch>
);

const EmbeddedWidgetRoutes = () => (
  <Switch>
    <Route exact path="/tracking/embedded-widgets" component={EmbeddedWidgetsPage} />
    <Route exact path="/tracking/embedded-widgets/start" component={EmbeddedWidgetsPage} />
    <Route exact path="/tracking/embedded-widgets/create/:step(choose-name|choose-language)" component={CreateWidgetPage} />
    <Route exact path="/tracking/embedded-widgets/:id(\d+)/edit" component={EmbeddedWidgetEditor} />
    <Route exact path="/tracking/embedded-widgets/:id(\d+)/install-code" component={InstallCodePage} />
    <Route exact path="/tracking/embedded-widgets/:id(\d+)/install-code/install-code" component={InstallCodePage} />
    <Route exact path="/tracking/embedded-widgets/:id(\d+)/install-code/send-instructions" component={InstallCodePage} />
    <Route exact path="/tracking/embedded-widgets/:id(\d+)/install-code/send-instructions/finish" component={InstallCodePage} />
    <Redirect exact from="/tracking/embedded-widgets/create" to="/tracking/embedded-widgets/create/choose-language" />
    <Route component={Error404} />
  </Switch>
);

const EmbeddedWidgetRoutesWithAccess = withTouchpointsAccess(withEmbeddedWidgetsAccess(EmbeddedWidgetRoutes));

const TrackingPageRoutes = () => (
  <Switch>
    <Route exact path="/tracking/tracking-pages" component={TrackingPagesPage} />
    <Route exact path="/tracking/tracking-pages/start" component={TrackingPagesPage} />
    <Route exact path="/tracking/tracking-pages/create/:step(choose-name|choose-localization)" component={CreateTrackingPage} />
    <Route exact path="/tracking/tracking-pages/:id(\d+)/edit" component={TrackingPageEditor} />
    <Route exact path="/tracking/tracking-pages/:id(\d+)/publish" component={PublishTrackingPage} />
    <Redirect exact from="/tracking/tracking-pages/create" to="/tracking/tracking-pages/create/choose-localization" />
    <Route component={Error404} />
  </Switch>
);

const TrackingPageRoutesWithAccess = withTouchpointsAccess(withTrackingPagesAccess(TrackingPageRoutes));

export default TrackingRoutes;