import React from 'react';
import PropTypes from 'prop-types';
import PageWithTitle from '../Wizard/PageWithTitle';
import Button from '../common/Button';
import Checkbox from '../common/Checkbox';
import TextArea from '../common/TextArea';
import { LabeledInput } from '../common/LabeledInput';
import { validateEmails } from '../../utils/emailValidationHelper';

import './InstallCodeHelpPage.scss';
import { withTranslation } from 'react-i18next';
import { getTranslatedEmailError } from '../../utils/getTranslatedEmailError';

export class InstallCodeHelpPage extends React.PureComponent {
  state = {
    emails: '',
    sendCopyToMe: false,
    subject: this.props.t(this.props.subject),
    message: this.props.message, // translate it before
  };

  onButtonClick = () => {
    const { onSendClick } = this.props;
    const { emails, sendCopyToMe, subject, message } = this.state;

    const newState = { subjectError: subject === '', emailsError: '' };

    const validatedEmails = validateEmails(emails);
    const allEmails = Object.keys(validatedEmails);

    newState.emailsError = getTranslatedEmailError(validatedEmails);
    this.setState(newState);

    if (!newState.emailsError && !newState.subjectError) {
      onSendClick(allEmails, sendCopyToMe, subject, message);
    }
  };

  onEmailListChange = (e) => {
    this.setState({ emails: e.target.value });
  };

  onSubjectChange = (e) => {
    this.setState({ subject: e.target.value });
  };

  onMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  onSendCopyToMeChange = (e) => {
    this.setState({ sendCopyToMe: e.target.checked });
  };

  render() {
    const { emails, subject, sendCopyToMe, message, emailsError, subjectError } = this.state;

    return (
      <PageWithTitle title={this.props.title} secondaryText={this.props.secondaryText}>
        <div styleName="content">
          <div styleName="input">
            <LabeledInput
              value={emails}
              label="Send to"
              placeholder="teammate1@yourcompany.com, teammate2@yourcompany.com"
              error={emailsError}
              onChange={this.onEmailListChange}
            />
          </div>
          <Checkbox checked={sendCopyToMe} onChange={this.onSendCopyToMeChange}>
            Send a copy to me
          </Checkbox>
          <div styleName="input">
            <LabeledInput
              value={subject}
              label="Email subject"
              error={subjectError ? 'Email subject can not be empty' : ''}
              onChange={this.onSubjectChange}
            />
          </div>
          <div styleName="input message">
            <TextArea
              label="Message"
              value={message}
              placeholder={this.props.t('placeholder text')}
              onChange={this.onMessageChange}
            />
          </div>
        </div>
        <Button onClick={this.onButtonClick} primary>
          Send instructions
        </Button>
      </PageWithTitle>
    );
  }
}

InstallCodeHelpPage.defaultProps = {
  onSendClick: () => {},
  subject: 'Prefilled but editable subject line',
  title: 'Send instructions',
  secondaryText: 'Install the sendwise tag',
};

InstallCodeHelpPage.propTypes = {
  onSendClick: PropTypes.func,
  subject: PropTypes.string,
  title: PropTypes.string,
  secondaryText: PropTypes.string,
  message: PropTypes.string,
};

export default withTranslation()(InstallCodeHelpPage);
