import React from 'react';
import {trackEvent} from '../../utils/googleAnalytics';
import ErrorPage from './ErrorPage';
import Locale from '../Translate/Locale';
import ErrorText from './ErrorText';

function Error403() {
  trackEvent({
    category: '403',
    action: window.location.pathname,
  });

  return (
    <ErrorPage
      upperHeader= "ERROR403_UPPER_HEADER"
      header = "ERROR403_HEADER"
      subHeader= "ERROR403_SUB_HEADER"
    >
      <ErrorText>
        <Locale>ERROR403_DESCRIPTION</Locale>
      </ErrorText>
    </ErrorPage>
  );
}

export default Error403;