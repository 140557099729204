import React from 'react';

import ShippingInfoStep from '../common/ShippingInfoStep';
import WholeDamageStepContainer from './WholeDamageStepContainer';

function WholeDamageShippingInfoStep(props) {
  return (
    <ShippingInfoStep
      {...props}
      as={WholeDamageStepContainer}
      showDeliveryInfo
    />
  );
}

export default React.memo(WholeDamageShippingInfoStep);