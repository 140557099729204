import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DesktopPageContainer from './DesktopPageContainer';
import MobilePageContainer from './MobilePageContainer';
import { getSelectedShop } from '../../reducers/selectors';
import useIsMobile from '../../hooks/useIsMobile';

function PageContainer(props) {
  const Container = useIsMobile() ? MobilePageContainer : DesktopPageContainer;

  return <Container {...props} />;
}

DesktopPageContainer.propTypes = {
  mobileMenuOnly: PropTypes.bool, // display menu only on mobile
  hideMobileExperienceWarning: PropTypes.bool, // do not display a footer with a text about bad mobile experience
  menuTitle: PropTypes.string,
  menuTitleBadge: PropTypes.element,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  selectedMenuItem: PropTypes.string,
  onSelectMenu: PropTypes.func,

  // desktop only
  menuCollapsed: PropTypes.bool,
  collapsible: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    selectedShop: getSelectedShop(state),
  };
}

export default connect(mapStateToProps, null)(PageContainer);
