import container from '../container';

function resetPasswordAction(email, password, token) {
  return dispatch => {
    dispatch(resetPasswordRequest());
    container.api.resetPassword(email, password, token)
      .then( data => {
        container.history.push('/login');
        dispatch(resetPasswordSuccess(data));
      })
      .catch( error => dispatch(resetPasswordError(error)));
  };
}

function resetPasswordRequest() {
  return { type: 'RESET_PASSWORD_REQUEST' };
}

function resetPasswordSuccess(data) {
  return { type: 'RESET_PASSWORD_SUCCESS', data };
}

function resetPasswordError(error) {
  return { type: 'RESET_PASSWORD_ERROR', error };
}

export default resetPasswordAction;