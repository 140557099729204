import React from 'react';
import AnalyticsPage from './AnalyticsPage';
import Locale from '../Translate';

const CarriersPage = (props) => {
  return (
    <AnalyticsPage {...props}>
      <span>
        <Locale>Carriers</Locale>
      </span>
    </AnalyticsPage>
  );
};

export default CarriersPage;