import React from 'react';

import WarehouseDeliveryConfirmationStep from '../common/WarehouseDeliveryConfirmationStep';
import WholeDamageStepContainer from './WholeDamageStepContainer';

function WholeDamageWarehouseDeliveryConfirmationStep(props) {
  return <WarehouseDeliveryConfirmationStep {...props} as={WholeDamageStepContainer} showDeliveryInfo />;
}

export default React.memo(WholeDamageWarehouseDeliveryConfirmationStep);
