import React from 'react';

import ShippingInfoStep from '../common/ShippingInfoStep';
import MissingItemStepContainer from './MissingItemStepContainer';

function MissingItemShippingInfoStep(props) {
  return <ShippingInfoStep {...props} as={MissingItemStepContainer} showDeliveryInfo />;
}

export default React.memo(MissingItemShippingInfoStep);
