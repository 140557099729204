import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import Wizard, { SwitchWithPagination } from '../Wizard';
import { getLocalizationsByShopId, selectedShopIdSelector } from '../../reducers/selectors';
import ChooseLocalizationStep from '../Wizard/ChooseLocalizationStep';
import ChooseNameStep from '../Wizard/ChooseNameStep';
import { createTrackingPage } from '../../actions/trackingPages';
import { getShopLocalizations } from '../../actions/shopLocalizations';

const steps = {
  name: 'choose-name',
  localization: 'choose-localization',
};

const urls = {
  base: '/tracking/tracking-pages',
  localization: '/tracking/tracking-pages/create/choose-localization',
  name: '/tracking/tracking-pages/create/choose-name',
};

export class CreateTrackingPage extends React.PureComponent {
  state = {};

  componentDidMount() {
    this.props.getShopLocalizations(this.props.shopId);
  }

  onClose = () => {
    this.props.history.push(urls.base);
  };

  onSelectLocalization = (localisationId) => {
    this.setState({ localisationId }, () => this.props.history.push(urls.name));
  };

  onCreateTrackingPage = (name) => {
    const { shopId } = this.props;
    const { localisationId } = this.state;

    this.props.createTrackingPage(shopId, name, localisationId);
  };

  render() {
    const {
      localisationId, // TODO: localizationId as prop would be ideal for testing ChooseNameStep as a standalone.
    } = this.state;

    const { localizations, step, history } = this.props;

    if (step === steps.name && !localisationId) {
      return <Redirect to={urls.localization} />;
    }

    const onBack = step !== steps.localization ? history.goBack : null;

    return (
      <Wizard onBack={onBack} onClose={this.onClose}>
        <SwitchWithPagination selected={step}>
          <ChooseLocalizationStep
            key={steps.localization}
            localizations={localizations}
            localizationId={localisationId}
            onSubmit={this.onSelectLocalization}
            subheader="Create a new tracking page"
            buttonText="Name my tracking page"
          />
          <ChooseNameStep key={steps.name} entityName="tracking page" onSubmit={this.onCreateTrackingPage} />
        </SwitchWithPagination>
      </Wizard>
    );
  }
}

CreateTrackingPage.defaultProps = {
  createTrackingPage: () => {},
  getShopLocalizations: () => {},
};

CreateTrackingPage.propTypes = {
  shopId: PropTypes.number,
  createTrackingPage: PropTypes.func,
  getShopLocalizations: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  const { step } = ownProps.match.params;
  const shopId = selectedShopIdSelector(state);
  const localizations = getLocalizationsByShopId(state, shopId);

  return {
    shopId,
    localizations,
    step,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createTrackingPage: (...args) => dispatch(createTrackingPage(...args)),
    getShopLocalizations: (shopId) => dispatch(getShopLocalizations(shopId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTrackingPage);
