import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CommsCenterPage from '../analytics/CommsCenterPage';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import { INVOICE_DETAILS } from '../../firewall/permissions';

const invoiceLookId = process.env.INVOICE_DETAILS_LOOK_ID;
const withInvoiceDetailsAccess = withPermissionRedirect(INVOICE_DETAILS);

const renderDashboard = (dashboardId, type) => (props) => <CommsCenterPage dashboardId={dashboardId} type={type} {...props} />;
const deliveryDashboard = renderDashboard('analytics::delivery_performance', 'dashboard');
const interfaceUsageDashboard = renderDashboard('analytics::partner_interface_usage', 'dashboard');
const invoiceDetailsDashboard = renderDashboard(invoiceLookId, 'look');

const CommsCenterRoutes = () => (
  <Switch>
    <Route exact path="/communication-center/delivery-performance" component={deliveryDashboard} />
    <Route exact path="/communication-center/partner-interface-usage" component={interfaceUsageDashboard} />
    <Route exact path="/communication-center/invoice-details" component={withInvoiceDetailsAccess(invoiceDetailsDashboard)} />
    <Redirect from="/communication-center" to="/communication-center/delivery-performance" />
  </Switch>
);

export default CommsCenterRoutes;
