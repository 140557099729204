import React from 'react';

import InvoiceInfoStep from '../common/InvoiceInfoStep';
import MissingItemStepContainer from './MissingItemStepContainer';

function InvestigationInvoiceInfoStep(props) {
  return <InvoiceInfoStep {...props} as={MissingItemStepContainer} isSubmitButton={true} />;
}

export default React.memo(InvestigationInvoiceInfoStep);
