import React from 'react';
import PropTypes from 'prop-types';

import ResultCard from './ResultCard';
import ResultHeader from './ResultHeader';
import ResultTable from './ResultTable';
import Text from '../../common/Text';
import Locale from '../../Translate/Locale';
import PlainButton from '../../common/PlainButton/PlainButton';
import { formatDate } from './dateHelper';

import ClaimsIcon from '../../../icons/claim.svg';

import './ShipmentCard.scss';

function ShipmentCard(props) {
  const {
    id,
    creationDate,
    trackingCode,
    customer = {},
    carrier = {},
    lastEvent,
    onClaim,
    claimable,
    claimId,
    claimStatus,
    claimType,
    hasShipmentDetailsAccess,
  } = props;

  const orderUrl = `/parcel-finder/shipments/${id}`;
  const showClaimButton = claimable && !claimId;
  const showClaimedTag = !!claimId;

  return (
    <ResultCard>
      <ResultHeader
        type="shipment"
        name="Shipment"
        labelForId="Shipment tracking code"
        id={trackingCode}
        lastEvent={lastEvent}
        link={orderUrl}
        claimStatus={claimStatus}
        claimType={claimType}
        showClaimedTag={showClaimedTag}
        showDetailsButton={hasShipmentDetailsAccess}
      >
        {showClaimButton && (
          <PlainButton icon={ClaimsIcon} onClick={onClaim}>
            Submit a claim
          </PlainButton>
        )}
      </ResultHeader>
      <ResultTable>
        <thead styleName="table-header">
          <tr>
            <th>
              <Text>
                <Locale>Creation date</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Name</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Address</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Email</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Carrier</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Carrier country</Locale>
              </Text>
            </th>
          </tr>
        </thead>
        <tbody styleName="table-body">
          <tr>
            <td>
              <Text ink>{formatDate(creationDate)}</Text>
            </td>
            <td>
              <Text ink>{customer.name}</Text>
            </td>
            <td>
              <Text ink>{customer.address}</Text>
            </td>
            <td>
              <Text ink>{customer.email}</Text>
            </td>
            <td>
              <Text ink>{carrier.name}</Text>
            </td>
            <td>
              <Text ink>{carrier.countryName}</Text>
            </td>
          </tr>
        </tbody>
      </ResultTable>
    </ResultCard>
  );
}

ShipmentCard.propTypes = {
  id: PropTypes.number,
  creationDate: PropTypes.string,
  trackingCode: PropTypes.string,
  customer: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
  }),
  lastEvent: PropTypes.shape({
    type: PropTypes.string,
    meaning: PropTypes.oneOf(['really_bad', 'bad', 'good', 'neutral']),
  }),
  carrier: PropTypes.shape({
    name: PropTypes.string,
    countryName: PropTypes.string,
  }),
  onClaim: PropTypes.func,
  claimable: PropTypes.bool,
};

export default React.memo(ShipmentCard);
