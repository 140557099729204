const routesOfDefaultDashboards = {
  order_fulfillment_performance: 'orders/fulfillment-performance',
  order_list: 'orders/list',
  order_status_overview: 'orders/status-overview',
  shipment_carrier_comparison: 'shipments/carrier-comparison',
  shipment_carrier_performance: 'shipments/carrier-performance',
  shipment_list: 'shipments/list',
  shipment_status_overview: 'shipments/status-overview',
  returns_overview: 'shipments/returns-overview',
  claims_overview: 'claims/claims-overview',
};

// TODO: does not take into account sorting order (see buildSortedMenuItems.js) thus not always returns the first one
export const getDefaultDashboardRoute = (dashboards) => {
  const firstDashboard = dashboards.find(({ type }) => type === 'dashboard');
  const firstDashboardNotCountryView = dashboards.find(
    ({ type, id }) => type === 'dashboard' && !id.startsWith('country_view_')
  );

  if (!firstDashboard) return '/analytics/start-guide';

  if (routesOfDefaultDashboards[firstDashboard.id]) return `/analytics/${routesOfDefaultDashboards[firstDashboard.id]}`;

  if (firstDashboardNotCountryView) return `/analytics/dashboards/${firstDashboardNotCountryView.id}`;

  return `/analytics/dashboards/${firstDashboard.id}`;
};
