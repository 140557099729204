import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './link.scss';

function ExternalLink(props) {
  const { to, children, intent = '' } = props;

  return (
    <a styleName={classNames('link', intent)} href={to} target="_blank">
      {children}
    </a>
  );
}

ExternalLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string.isRequired,
  intent: PropTypes.oneOf(['underline', '']),
};

export default React.memo(ExternalLink);
