import React from 'react';
import {connect} from 'react-redux';
import { getApiError } from '../reducers/selectors';

import Error5xx from './error-pages/Error5xx';
import Error403 from './error-pages/Error403';

export class ApiError extends React.Component {
  render() {
    const { apiError: { errorCode } } = this.props;

    if (errorCode === 403) {
      return <Error403 />;
    }

    if (500 <= errorCode) {
      return <Error5xx message={errorCode.toString()} />;
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    apiError: getApiError(state),
  };
}

export default connect (mapStateToProps) (ApiError);