import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateEmbeddedWidgetSettings, publishEmbeddedWidget } from '../../../actions/widgetSettings';
import Button from '../../common/Button';
import Link from '../../common/Link';
import { previewTrackingSelector, selectedShopIdSelector } from '../../../reducers/selectors';
import { getEmbeddedWidgetEditorData } from '../../../actions/editorActions';
import statusEnum from '../../../constants/embeddedWidgetStatusEnum';
import { withNotifications } from '../../Notification';
import WidgetsEditorContainer from '../../WidgetEditionPage/WidgetsEditorContainer';
import editorTypeEnum from '../visualEditorTypeEnum';
import WidgetPreview from '../../WidgetEditionPage/WidgetPreview';
import EditorContext from '../EditorContext';
import editorConfig from './editorConfig';
import { injectWidget } from '../tracking-page-editor/editorConfig';

export class EmbeddedWidgetEditor extends React.PureComponent {
  componentDidMount() {
    this.props.getEditorData(this.props.shopId);
  }

  onExit = () => {
    this.props.history.push('/tracking/embedded-widgets');
  };

  onSave = () => {
    this.props
      .updateSettings()
      .then(() =>
        this.props.showNotice({
          message: 'Your embedded widget is successfully saved',
          type: 'success',
        })
      )
      .catch(() => this.props.showError());
  };

  onPublish = () => {
    this.props
      .updateSettings()
      .then(() => this.props.publish())
      .then(() =>
        this.props.showNotice({
          message: 'Your embedded widget is successfully published',
          type: 'success',
        })
      )
      .catch(() => this.props.showError());
  };

  renderHeaderButtons = (status) => {
    return (
      <React.Fragment>
        <Button onClick={this.onSave} midnight negativeOutline>
          Save
        </Button>
        {this.renderPublishButton(status)}
      </React.Fragment>
    );
  };

  renderPublishButton(status) {
    if (status === statusEnum.LIVE) {
      return (
        <Button onClick={this.onPublish} midnight>
          Publish
        </Button>
      );
    }

    return (
      <Link to={`/tracking/embedded-widgets/${this.props.embeddedWidgetId}/install-code`}>
        <Button midnight>Install code</Button>
      </Link>
    );
  }

  render() {
    const { trackingInfo } = this.props;

    return (
      <EditorContext.Provider value={editorConfig}>
        <WidgetsEditorContainer
          editorType={editorTypeEnum.EMBEDDED_WIDGET}
          onExit={this.onExit}
          onSave={this.onSave}
          renderHeaderButtons={this.renderHeaderButtons}
        >
          {(widgets, settings) =>
            trackingInfo && <WidgetPreview trackingInfo={trackingInfo} settings={{ widgets, settings }} />
          }
        </WidgetsEditorContainer>
      </EditorContext.Provider>
    );
  }
}

EmbeddedWidgetEditor.defaultProps = {
  history: {
    push: () => {},
  },
  getEditorData: () => {},
  updateSettings: () => {},
  publish: () => {},
};

EmbeddedWidgetEditor.propTypes = {
  embeddedWidgetId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  shopId: PropTypes.number,
  getEditorData: PropTypes.func,
  updateSettings: PropTypes.func,
  publish: PropTypes.func,
  showNotice: PropTypes.func,
  showError: PropTypes.func,
  trackingInfo: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    embeddedWidgetId: ownProps.match.params.id,
    shopId: selectedShopIdSelector(state),
    trackingInfo: previewTrackingSelector(state).data,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const embeddedWidgetId = ownProps.match.params.id;

  return {
    getEditorData: (shopId) => dispatch(getEmbeddedWidgetEditorData(embeddedWidgetId, shopId)),
    updateSettings: () => dispatch(updateEmbeddedWidgetSettings(embeddedWidgetId)),
    publish: () => dispatch(publishEmbeddedWidget(embeddedWidgetId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNotifications()(EmbeddedWidgetEditor));
