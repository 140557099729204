import React from 'react';
import PropTypes from 'prop-types';

import InvoiceInfoStep from '../common/InvoiceInfoStep';
import WholeDamageStepContainer from './WholeDamageStepContainer';

function WholeDamageInvoiceInfoStep(props) {
  return <InvoiceInfoStep {...props} as={WholeDamageStepContainer} isSubmitButton={true} />;
}

WholeDamageInvoiceInfoStep.propTypes = {
  claim: PropTypes.object,
  onChange: PropTypes.func,
};

export default React.memo(WholeDamageInvoiceInfoStep);
