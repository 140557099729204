import React from 'react';

import SearchClaimInfoStep from '../common/SearchClaimInfoStep';
import InvestigationStepContainer from './InvestigationStepContainer';

function InvestigationSearchClaimInfoStep(props) {
  return <SearchClaimInfoStep {...props} as={InvestigationStepContainer} />;
}

export default React.memo(InvestigationSearchClaimInfoStep);
