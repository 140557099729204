import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from './InputLabel';
import { withTranslation } from 'react-i18next';

import './Input.scss';

class Input extends React.PureComponent {
  render() {
    // i18n and tReady are unused, we don't want them to be spread into HTMLElement attributes via {...props} 
    const { label, error, placeholder, value, t, i18n, tReady, leftIcon, rightIcon, ...props } = this.props;

    const elements = (
      <React.Fragment>
        {leftIcon && <div styleName="icon icon-left">{leftIcon}</div>}
        {rightIcon && <div styleName="icon icon-right">{rightIcon}</div>}
        <input styleName="input" value={value || ''} placeholder={t(placeholder)} {...props} />
      </React.Fragment>
    );

    // Wrap into container only if we have another elements except input (e.g. icons)
    const wrap = leftIcon || rightIcon;

    return (
      <InputLabel label={label} error={error}>
        {wrap ? <div styleName="input-container">{elements}</div> : elements}
      </InputLabel>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
};

export default withTranslation()(Input);
