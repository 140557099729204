import container from '../container';
import { cleanWidgetId } from './widgetSettings';

export function getTrackingPages(shopId) {
  return (dispatch) => {
    dispatch(requestTrackingPages());
    container.api
      .trackingPages(shopId)
      .then((data) => dispatch(receivedTrackingPages({ shopId, data })))
      .catch((error) => dispatch(errorTrackingPages(error)));
  };
}

export function requestTrackingPages() {
  return { type: 'TRACKING_PAGES_REQUEST' };
}

function receivedTrackingPages(data) {
  return { type: 'TRACKING_PAGES_SUCCESS', data };
}

export function errorTrackingPages(error) {
  return { type: 'TRACKING_PAGES_ERROR', error };
}

export function createTrackingPage(shopId, name, shopLocalization) {
  return (dispatch) => {
    dispatch(requestTrackingPages());
    return container.api
      .createTrackingPage({ shop: shopId, name, shopLocalization })
      .then((data) => {
        container.history.push(`/tracking/tracking-pages/${data.id}/edit`);
        return { data };
      })
      .catch((error) => {
        dispatch(errorTrackingPages(error));
        return { error };
      });
  };
}

export function duplicateTrackingPage(trackingPageId, newData, shop) {
  return container.api
    .getTrackingPageSettings(trackingPageId)
    .then((trackingPage) => cleanWidgetId(trackingPage))
    .then((trackingPage) => {
      const { settings, widgets } = trackingPage;

      const { name, localization: shopLocalization } = newData;

      const duplicate = {
        shop,
        shopLocalization,
        name,
        settings,
        widgets,
      };

      return container.api.createTrackingPage(duplicate);
    });
}
