import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { embeddedWidgetsSelector, getEmbeddedWidgetsByShopId, selectedShopIdSelector } from '../../reducers/selectors';
import { getEmbeddedWidgets } from '../../actions/embeddedWidgets';
import EmbeddedWidgetsOverview from './EmbeddedWidgetsOverview';
import TrackingContainer from '../touchpoints/TrackingContainer';
import TouchpointsOverviewRedirect from '../touchpoints/TouchpointsOverviewRedirect';
import TouchpointsEmptyOverview from '../touchpoints/TouchpointsEmptyOverview';
import TouchpointsOverviewSwitch from '../touchpoints/TouchpointsOverviewSwitch';
import Loader from '../common/Loader';
import TrackingIcon from '../../icons/tracking.svg';

import urls from './urls';

const embeddedWidgetLink = 'https://support.portal.sevensenders.com/support/solutions/folders/15000003852';

export function EmbeddedWidgetsPage(props) {
  const { shopId, isLoading, loaded, widgets = [], getWidgets, location } = props;

  useEffect(() => {
    getWidgets(shopId);
  }, [shopId]);

  return (
    <TrackingContainer location={location}>
      {!isLoading && loaded && <TouchpointsOverviewRedirect pathname={location.pathname} data={widgets} urls={urls} />}
      <TouchpointsOverviewSwitch isLoading={isLoading} loaded={loaded} data={widgets}>
        <Loader key="loader" />
        <TouchpointsEmptyOverview
          key="empty"
          icon={TrackingIcon}
          pageTitle="No embedded widgets yet"
          title="NO_EMBEDDED_WIDGETS"
          buttonText="CREATE_YOUR_FIRST_EMBEDDED_WIDGET"
          buttonLink={urls.create}
          link={embeddedWidgetLink}
          linkText="CREATE_EMBEDDED_WIDGETS"
        />
        <EmbeddedWidgetsOverview key="data" shopId={shopId} widgets={widgets} onReload={getWidgets} />
      </TouchpointsOverviewSwitch>
    </TrackingContainer>
  );
}

function mapStateToProps(state) {
  const shopId = selectedShopIdSelector(state);
  const widgets = getEmbeddedWidgetsByShopId(state, shopId);
  const { isLoading, loaded } = embeddedWidgetsSelector(state);

  return {
    shopId,
    widgets,
    isLoading,
    loaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWidgets: (shopId) => dispatch(getEmbeddedWidgets(shopId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmbeddedWidgetsPage);
