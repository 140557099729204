import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getReturnInfo, selectParcelFinderReturns } from '../../../selectors/parcelFinderReturnsSelectors';
import { createReturnShipments, setLabelsCount } from '../../../reducers/parcelFinderReturnsReducer';
import { parcelFinderReload } from '../../../reducers/parcelFinderReducer';
import useNotifications from '../../../hooks/useNotifications';
import CreateReturn from './CreateReturn';
import { isFulfilledAction } from '../../../actions/apiActionHelper';

function ReturnsSidePanel(props) {
  const dispatch = useDispatch();
  const info = useSelector(getReturnInfo);
  const { labelsCount, isLoading, returnShipments } = useSelector(selectParcelFinderReturns);

  const { showNotice, showError } = useNotifications();

  const onCreateReturn = useCallback(async () => {
    const action = await dispatch(createReturnShipments());

    if (isFulfilledAction(action)) {
      showNotice({
        message: 'Return successfully created',
        type: 'success',
      });
    } else {
      showError();
    }

    dispatch(parcelFinderReload());
  }, [dispatch, showNotice, showError]);

  const onChangeLabelsCount = useCallback((value) => dispatch(setLabelsCount(value)), [dispatch]);

  return (
    <CreateReturn
      isOpen={props.isOpen}
      onClose={props.onClose}
      onCreate={onCreateReturn}
      onChangeLabelsCount={onChangeLabelsCount}
      info={info}
      labelsCount={labelsCount}
      isLoading={isLoading}
      returnShipments={returnShipments}
    />
  );
}

ReturnsSidePanel.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default React.memo(ReturnsSidePanel);
