import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '../../common2/DropdownButton/MenuItem';
import './DropdownMenuItem.scss';

function DropdownMenuItem(props) {
  const { children, icon: Icon, onClick, intent = 'base' } = props;

  return (
    <MenuItem onClick={onClick} intent={intent}>
      <div styleName="item">
        <Icon styleName="icon" />
        {children}
      </div>
    </MenuItem>
  );
}

DropdownMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  intent: PropTypes.oneOf(['base', 'danger']),
};

export default React.memo(DropdownMenuItem);
