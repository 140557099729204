import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  selectedShopIdSelector,
  previewTrackingSelector,
  getSelectedShop,
} from '../../../reducers/selectors';
import {
  updateTrackingPageSettings,
} from '../../../actions/widgetSettings';
import Button from '../../common/Button';
import statusEnum from '../../../constants/trackingPageStatusEnum';
import { getTrackingPageEditorData } from '../../../actions/editorActions';
import { publishTrackingPageAction } from '../../../actions/publishActions';
import { withNotifications } from '../../Notification';
import WidgetsEditorContainer from '../../WidgetEditionPage/WidgetsEditorContainer';
import WidgetPreview from '../../WidgetEditionPage/WidgetPreview';
import EditorContext from '../EditorContext';
import editorConfig, { injectWidget } from './editorConfig';
import { getPreviewTrackingData } from '../../../actions/previewTracking';

export class TrackingPageEditor extends React.PureComponent {
  componentDidMount() {
    this.props.getEditorData(this.props.shopId);
  }

  onExit = () => {
    this.props.history.push('/tracking/tracking-pages');
  };

  onSave = () => {
    this.props.updateSettings()
      .then(() => this.props.showNotice({
        message: 'Your tracking page is successfully saved',
        type: 'success',
      }))
      .catch(() => this.props.showError());
  };

  onPublish = () => {
    this.props.history.push(`/tracking/tracking-pages/${this.props.trackingPageId}/publish`);
  };

  onUpdate = () => this.props.updateSettings()
    .then(() => this.props.publish())
    .then(() => this.props.showNotice({
      message: 'Your tracking page is successfully updated',
      type: 'success',
    }))
    .catch(() => this.props.showError());

  onChangeLanguage = (language) => {
    const {
      shopId,
      getTrackingData,
    } = this.props;

    getTrackingData(shopId, language);
  };

  renderHeaderButtons = (status) => {
    const published = status === statusEnum.PUBLISHED;

    return (
      <React.Fragment>
        <Button onClick={this.onSave} midnight negativeOutline>
          Save
        </Button>
        <Button onClick={published ? this.onUpdate : this.onPublish} midnight>
          { published ? 'Update' : 'Publish' }
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { trackingInfo } = this.props;

    return (
      <EditorContext.Provider value={editorConfig}>
        <WidgetsEditorContainer
          onExit={this.onExit}
          onSave={this.onSave}
          renderHeaderButtons={this.renderHeaderButtons}
        >
          {(widgets, settings) =>
            trackingInfo && (
              <WidgetPreview
                trackingInfo={trackingInfo}
                settings={ { widgets, settings } }
                onChangeLanguage={this.onChangeLanguage}
              />
            )
          }
        </WidgetsEditorContainer>
      </EditorContext.Provider>
    );
  }
}

TrackingPageEditor.defaultProps = {
  getEditorData: () => {},
  getTrackingData: () => {},
};

TrackingPageEditor.propTypes = {
  trackingPageId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  shopId: PropTypes.number.isRequired,
  getEditorData: PropTypes.func,
  getTrackingData: PropTypes.func,
  updateSettings: PropTypes.func.isRequired,
  publish: PropTypes.func.isRequired,
  showNotice: PropTypes.func,
  showError: PropTypes.func,
  trackingInfo: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  return {
    trackingPageId: ownProps.match.params.id,
    shopId: selectedShopIdSelector(state),
    trackingInfo: previewTrackingSelector(state).data,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const trackingPageId = ownProps.match.params.id;

  return {
    getEditorData: (shopId) => dispatch(getTrackingPageEditorData(trackingPageId, shopId)),
    getTrackingData: (shopId, language) => dispatch(getPreviewTrackingData(shopId, language)),
    updateSettings: () => dispatch(updateTrackingPageSettings(trackingPageId)),
    publish: () => publishTrackingPageAction(trackingPageId),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withNotifications()(TrackingPageEditor)
);